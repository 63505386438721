<template>
<div>
  <v-card class="mx-auto mt-5" max-width="800" elevation="22">
    <v-container>
      <p class="text-h3">Merci pour votre achat !</p>
      <p>Nous créons vos codes d’accès et revenons vers vous rapidement. Vous allez recevoir un email de confirmation. N’hésitez pas à nous contacter à info@gottayoga.app si vous avez des questions.</p>
    </v-container>
  </v-card>
  <base-scroll-button></base-scroll-button>
  <base-contact></base-contact>
  <base-social-media></base-social-media>
</div>
</template>

<script>
export default {
  name: 'PaymentSuccess',
  components: {
    BaseScrollButton: () =>
      import('@/components/base/ScrollButton'),
    BaseContact: () =>
      import('@/components/base/Contact'),
    BaseSocialMedia: () =>
      import('@/components/base/SocialMedia')
  }
}
</script>
