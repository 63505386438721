<template>
<div id="code">
  <section-hero v-bind="code"></section-hero>
  <section-steps></section-steps>
  <base-scroll-button></base-scroll-button>
</div>
</template>

<script>
export default {
  name: 'Code',
  components: {
    SectionHero: () =>
      import('@/components/sections/code/Hero'),
    SectionSteps: () =>
      import('@/components/sections/code/Steps'),
    BaseScrollButton: () =>
      import('@/components/base/ScrollButton'),
  },
  data() {
    return {
      code: [{
        title: this.$i18n.t("app_name_short"),
        subtitle: this.$i18n.t("use_access_code"),
        image: require("@/assets/logo.bulle.png"),
      }]
    }
  }
}
</script>
