import Home from '../components/views/home/Home.vue'
import Homeb2b from '../components/views/homeb2b/Homeb2b.vue'
import Offer from '../components/views/homeb2b/Offer.vue'
import PaymentSuccess from '../components/views/PaymentSuccess'
import Code from '../components/views/Code'
import Conditions from '../components/views/Conditions.vue'
import Confidentialite from '../components/views/Confidentialite.vue'
import Privacy from '../components/views/Privacy.vue'
import Terms from '../components/views/Terms.vue'

export default [
    { path:'/', component: Homeb2b },
    { path:'/offer', component: Offer },
    { path:'/code', component: Code },
    { path:'/conditions', component: Conditions },
    { path:'/confidentialite', component: Confidentialite },
    { path:'/privacy', component: Privacy },
    { path:'/terms', component: Terms },
    { path: '/paymentSuccess', component: PaymentSuccess },
    {
      path: '/code',
      redirect: '/fr/code'
    },
    {
      path: '/:language/code',
      name: 'useCode',
      component: Code
    },
    {
      path: '/404',
      component: Home
    },
    {
      path: '*',
      component: Home
    }
]
