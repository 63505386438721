import { i18n } from "@/main"
import localforage from 'localforage'

const overridenLanguageKey = 'overridenLanguage'
const collectionsKey = 'collections'
export const supportedLanguages = ['en', 'fr', 'de', 'it', 'fi']
export const defaultLanguage = 'default'

export async function initLanguage(paramsLanguage) {
  let locale = await overridenLanguage()
  if (locale === defaultLanguage) {
    locale = null
  }
  if (typeof paramsLanguage !== "undefined" && paramsLanguage !== null) {
    locale = paramsLanguage
  }
  if (typeof locale !== "undefined" && locale !== null) {
    if (supportedLanguages.indexOf(locale) === -1) {
      locale = "en";
    }
    i18n.locale = locale;
  }
  // Uncomment to force a given language for testing
  // i18n.locale = "fr"
}

export function language() {
  return i18n.locale;
}

export async function overridenLanguage() {
  return 'fr'
}

export async function saveOverridenLanguage(code) {
  try {
    await localforage.setItem(overridenLanguageKey, code)
  } catch (err) {
    console.log("Error while setting overridenLanguage: ", code, ", ", err)
  }
}

export async function loadCollections() {
  try {
    const collections = await localforage.getItem(collectionsKey)
    if (collections !== null) {
      return collections
    }
  } catch (err) {
    console.log("Error while getting localforage collections: ", err)
  }
  return []
}

export async function saveCollections(collections) {
  try {
    await localforage.setItem(collectionsKey, collections)
  } catch (err) {
    console.log("Error while setting localforage collections: ", collections, ", ", err)
  }
}

export function iosURL() {
  return "https://itunes.apple.com/fr/app/yoga-gotta-joga-hatha-classes/id880583924?at=1010lmSW&l=" + language() + "&mt=8";
}

export function androidURL() {
  return "https://play.google.com/store/apps/details?id=com.gottajoga.androidplayer&hl=" + language();
}

export function webURL() {
  return "https://gottayoga.app";
}

export function subscribeURL() {
  return "/" + language() + "/payment"
}

export function helpURL() {
  return "https://gottajoga.helpshift.com/hc/" + language() + "/?p=web"
}

export function contactUsURL() {
  return "https://gottajoga.helpshift.com/hc/" + language() + "/3-gotta-joga---learn-yoga-poses-practices/contact-us/?p=web"
}

export function termsPath() {
  if (language() === "fr") {
    return "/conditions"
  } else {
    return "/terms"
  }
}

export function privacyPath() {
  if (language() === "fr") {
    return "/confidentialite"
  } else {
    return "/privacy"
  }
}

export function buyGiftCardPath() {
  return "/" + language() + "/payGiftCard"
}

export function useGiftCardPath() {
  return "/" + language() + "/useGiftCard"
}

export function useCodePath() {
  return "https://gottayoga.app/" + language() + "/code"
}
