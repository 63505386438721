<template>
<base-section id="conditions" style="padding-top: 0!important">
  <v-container fluid class="pa-16 ma-2">
    <p><strong>1. Legal information and scope</strong></p>
    <p>1.1. Legal Information</p>
    <p>The site "Gotta Yoga" (hereinafter the "Site"), which is accessible at: <a href="https://gottayoga.app">https://gottayoga.app</a>, via our applications (hereinafter the "apps") or our partners' websites, is created, developed and
      operated by the company Gotta Apps SAS, a limited liability company under French law, with a shared capital of EUR 20,000, headquartered at chez InExtenso, 24 chemin des Verrières, 69260 Charbonnières les Bains, registered with the Lyon Trade
      and Companies Register under number 801905 266 R.C.S. Lyon, and having the VAT number FR 89 801905266 (hereinafter "GottaApps" or "us"). You may contact us by telephone at +33 (0)6 37 64 33 58 and by email at <a href="mailto:info@gottayoga.app">info@gottayoga.app</a></p>
    <p>1.2. Scope</p>
    <p>These terms and conditions of use and sale (the "Terms") apply to any visitor ("you" or "user") of the Site.</p>
    <p>Their purpose is to define the terms for accessing the Site and the services available on the Site, as well as its terms of use.</p>
    <p>Any access and / or use of the Site implies the acceptance and respect of all Terms. Read the Terms carefully before using the Site or its services. If you do not agree to any of the provisions of these Terms, please do not use the Site. In
      order to use certain Site services, we may ask you to accept additional terms.</p>
    <p>1.3. Amendments</p>
    <p>We reserve the right to revise and amend these Terms from time to time without notice in particular to reflect changes in market conditions affecting our business, technological changes, changes in payment methods, changes in relevant laws
      and regulatory requirements and changes in the capabilities of our system. Your continued use of the services after such changes are made, demonstrates your acceptance of these amendments. If your purchase of services is in progress, we will
      notify you in advance of any changes to these terms and you may choose to cancel your subscription to our services, without penalty, before the amended terms come into effect.</p>
    <p>&nbsp;</p>
    <p><strong>2. System Requirements</strong></p>
    <p>The use of the Site and its services requires a high-speed Internet connection for personal computers and a mobile Internet service (Edge, 3G, 4G, 5G, Wifi) for portable devices. These connections are not provided by Gotta Apps, so the user
      must first subscribe individually to a broadband Internet connection and / or a mobile internet provider in order to use Site and its services. A fourth or fifth generation (4G or 5G) mobile Internet connection is highly recommended.</p>
    <p>As an user, you may activate the service from a personal computer (PC or MAC) with a compatible operating system and browser and from a compatible mobile device (hardware, operating system, browser compatible). The service is only accessible
      by one connection at a time (personal computer or a registered compatible portable device). Gotta Apps has the technical means to check the number of simultaneous connections on the same account and thus to detect multiple connection attempts.
      To enjoy the Site and its services via your smartphone or other device, your device must meet certain system requirements. These requirements can be found on the Site and the app stores of Apple and Google.</p>
    <p>&nbsp;</p>
    <p><strong>3. Access and Use</strong></p>
    <p>3.1. Creating an Account</p>
    <p>In order to access the Site services and register for the corresponding subscriptions (as described below), you must first create an account via the relevant Site by completing the registration form where you will fill in online as indicated
      and requested, and in the fields provided for this purpose, the information required to take into account your registration, including a valid email address, choosing a password and then confirming your registration, or alternatively by using
      your existing Apple ID, Facebook personal account by clicking on the "Validate" tab, and accepting these Terms in advance.</p>
    <p>For your subsequent visits, you will be able to access the services by entering your e-mail address and your password as recorded during your registration.</p>
    <p>If you forget your password, there is a feature to reset it on the Site.</p>
    <p>Each user may only create one account. If we disable your account, you may not try to create another without our permission.</p>
    <p>3.2. Login and password</p>
    <p>The username and password you chose when creating your account are your own, personal and confidential.</p>
    <p>You agree to keep your username and password secret and not to disclose it in any form whatsoever. Storing, using and transmitting your username and password are your sole responsibility. Particularly, you are hereby notified of the inherent
      lack of security of using the auto-save feature for your login details that your computer system may allow, therefore, you declare that you are assuming full responsibility for using it, and any possible consequences thereof.</p>
    <p>Any use of your username and password will be deemed to have been made or duly authorised by you.</p>
    <p>Therefore, we may not in any way be held responsible for any harmful consequences resulting from unlawful, fraudulent or abusive use of your username and password and the access to the Site by a third party not expressly authorised by you
      that would result from your fault or negligence.</p>
    <p>In case of theft or misappropriation of your username and password, it is your responsibility to notify us immediately by registered letter with acknowledgment of receipt.</p>
    <p>In the event it is impossible to login with your username and your password, you must inform us without delay by telephone or email.</p>
    <p>You may not transfer your account without first obtaining our written permission, and you may not impersonate or attempt to impersonate others.</p>
    <p>3.3. Services</p>
    <p>Once your account is created, you become a member of our Site, which gives you access to certain sections, features and functions of the website and our application, which are not available to non-members. You may also agree to receive emails
      about occasional special offers, marketing communications, communications related to your user lifecycle, and technical or quality of service messages from our platform. You may easily unsubscribe from these emails by following the opt-out
      instructions in these emails. Memberships and subscriptions to our Services are not transferable and therefore cannot be sold or exchanged or transferred in any way. The services you will be able access as a member include:</p>
    <p>a) Discovery Pack</p>
    <p>The Site offers a broadcast service of several yoga sessions, referred to as the 'Discovery Pack,' to users who have previously created an account, without any purchase commitment.</p>
    <p>b) "Premium" Access</p>
    <p>Any member can also access a premium paid subscription service giving access to all content, including the free trial sessions of the Discovery Pack and additional content. All subscription services provide access to the website and
      applications. You can become a Subscriber by purchasing a subscription to the services on our website or in the applications, when this is authorised by the partners of the app stores.</p>
    <p>GottaApps offers four types of premium subscription:</p>
    <ol>
      <li>a monthly Premium subscription, renewable by tacit agreement for an identical duration unless terminated in accordance with the terms of these Conditions,</li>
      <li>a semi-annual Premium subscription, renewable by tacit agreement for an identical duration unless terminated in accordance with the terms of these Conditions, payable in a single payment when subscribing to the subscription</li>
      <li>an annual Premium subscription, renewable by tacit agreement for an identical duration unless terminated in accordance with the terms of these Conditions, payable in a single payment when subscribing to the subscription</li>
      <li>a lifetime subscription, that renews itself automatically, unless terminated in accordance with the terms and conditions</li>
    </ol>
    <p>Regarding the monthly, semi-annual, yearly and lifetime subscription, one month is made of 30 calendar days, 6 months of 182 days, and one year of 365 days.</p>
    <p>The lifetime subscription constitutes 50 years or ends with the date Gotta Apps ceases to commercially offer the products. The right to access the products is not transferable or inheritable; an heir or heiress does not own the right to access
      the services in case the subscriber ceases prior to the expiration of the 100-year subscription.</p>
    <p>Gotta Apps also offers access to the service via gift card:</p>
    <ul>
      <li>A gift card is billed and paid at once at the time of purchase, and does not lead to automatic renewal.</li>
      <li>After paying, the buyer accesses his printable gift card (in PDF format) on the site or by email.</li>
      <li>Duration of subscription: the beneficiary becomes a Subscriber during the term of subscription registered on the gift card from the date of activation of the card by the beneficiary. To activate, the beneficiary must indicate in his
        membership account the number of the gift card.</li>
      <li>Each card is associated with a unique user account, declared during activation, and cannot be transmitted or transferred later. Its use is exclusively the responsibility of the holder of the gift card.</li>
      <li>There are no refunds after activating the code on the gift card.</li>
      <li>Validity period: The validity period of gift cards is 12 months from the card's date of issue.</li>
      <li>The buyer confirms having verified that the beneficiary can actually access the "Gotta Yoga" yoga service (appropriate version of smart telephone, tablet, computer, operating system, browser, or app)</li>
    </ul>
    <p>In order to subscribe the aforementioned subscriptions, or to buy a gift card, the user must choose the chosen formula (monthly subscription, semi-annual, annual, gift card), and fill in the mandatory fields of the form accessible on the Site
      or the apps, accept these Terms and validate the order by clicking on the "VALIDATE AND PAY" button. The user will receive confirmation of his order by email.</p>
    <p>Our obligation to provide you with this premium service only begins (excluding the gift card) when we have received confirmation of the payment of your order and we have confirmed your purchase by e-mail. We will need to confirm your order
      when it takes place and confirm by email that you have access to the service in accordance with the purchased subscription. Please indicate the order number in any subsequent correspondence with us.</p>
    <p>Prices are in euros include VAT, unless otherwise indicated.</p>
    <p>We may at any time and from time to time, at our sole discretion, change subscriptions, fees and taxes, or add new subscriptions, fees and taxes in connection with all of our services. We will notify you by e-mail at least 30 (thirty)
      calendar days before any change. If you do not accept the change, you can unsubscribe from your account settings or <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>3.4. Guarantees</p>
    <p>By creating an account on the Site and using the services, you certify, guarantee and agree that:</p>
    <ul>
      <li>the information you provide is personal, accurate and you are not using personal information of others without permission,</li>
      <li>you are legally able to enter into legal commitments;</li>
      <li>you are at least 13 years old and if you are under the age of 18 (or of adult age pursuant to the law applicable to you), you create your account and you are engaged under the direction, supervision and with the consent of your legal
        representatives;</li>
      <li>your personal information will be updated if necessary.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>4. Payment</strong></p>
    <p>4.1. Payment Methods</p>
    <p>Several payment methods are available on the site (Credit card, PayPal). On mobiles or tablet under iOS, the proposed payment method is Apple iTunes. On mobiles or tablet under Android, the proposed payment method is Google
      Play Store Pay. It is specified that when the user communicates to Gotta Apps his bank details, they are not preserved by GottaApps.</p>
    <p>In order to optimize the security of the transactions, Gotta Apps chose Paypal and Stripe payment systems, as well as iTunes (Apple) for mobiles under IOS and Play Store (Google) for mobiles under Android. The guarantees granted by GottaApps for the
      security of transactions are identical to those obtained respectively by Gotta Apps from the publishers of the aforementioned payment systems.</p>
    <p>In the absence of evidence to the contrary produced by the Subscriber, the computerised records stored in the computer systems of GottaApps and its partners under reasonable conditions of security will be considered as evidence of the
      communications, orders, validations and payments transacted between the Subscriber and Gotta Apps.</p>
    <p>4.2. Payment Terms</p>
    <ul>
      <li>Monthly subscription: the monthly subscriptions are payable by recurring monthly direct debit on the chosen method of payment. The first payment will be made in advance when subscribing to the subscription and then each month, by direct
        debit on the method of payment provided until termination of the subscription, that the termination is at his initiative or that from Gotta Apps.</li>
      <li>Semi-annual subscription: semi-annual subscriptions are payable by semi-annual recurring automatic debit on the chosen method of payment. The first payment will be made in advance when subscribing to the subscription and then each
        anniversary date of the subscription, by direct debit on the method of payment provided until termination of the subscription, that the termination is to his initiative or that of Gotta Apps</li>
      <li>Annual subscription: the annual subscriptions are payable by annual recurring automatic withdrawal on the chosen method of payment. The first payment will be made in advance when subscribing to the subscription and then each anniversary
        date of the subscription, by direct debit on the method of payment provided until termination of the subscription, that the termination is to his initiative or that of Gotta Apps</li>
      <li>Gift Card: As part of a single purchase without subscription (gift card of a certain duration: 6 months, 1 year...) the buyer agrees to pay the full amount to the order.</li>
    </ul>
    <p>No partial refund (corresponding to part of the period) will be accepted in case of unilateral termination without cause during the period.</p>
    <p>4.3. Collection Authorisation</p>
    <p>Excluding the gift card, you authorize Gotta Apps and the payment provider concerned to debit the same payment method used for the subscription of the initial subscription during the applicable subscription renewals. In the case of tacit
      renewal, the rate then in force at the renewal date for the relevant subscription will be fully applicable. You agree to promptly notify Gotta Apps of any change or change of payment method you have provided, because in the meantime, all
      subscriptions will be suspended. You are responsible for any applicable fees, including applicable taxes, and any subscriptions you have purchased. In the event that we cannot collect the amount of your subscription at the agreed deadline, you
      are still responsible for the previous deadlines and we reserve all rights and actions in this regard.</p>
    <p>&nbsp;</p>
    <p><strong>5. Complaints, Cancellation and Right of Withdrawal</strong></p>
    <p>5.1. Complaints</p>
    <p>You may send your complaints by email to: <a href="mailto:info@gottayoga.app">info@gottayoga.app</a></p>
    <p>5.2. Termination on your part</p>
    <p>You may terminate your monthly, semi-annual or annual subscription at any time by accessing the your account configuration on the Site, with a notice period of at least 48 (forty-eight) hours before the current deadline. The termination will
      be effective at the end of the period, monthly, semi-annual or annual, in progress.</p>
    <p>5.3. Termination by us</p>
    <p>We may suspend or terminate, without prior notice or recourse to the Courts, your use of our services as a result of fraud or breach of an obligation under these Terms, including without limitation, the failure to collect the amount of your
      agreed-upon subscription, unauthorised copying or downloading of our audio or video content from the Site or the apps or any violation of the limited-use licence that we grant you as part of your access to our services.</p>
    <p>5.4. Right to Retract</p>
    <p>In accordance with the provisions of the French Consumer Law, you acknowledge that the provision of services begins upon receipt of the email confirmation of your order and that you can exercise your right of withdrawal
      once the services have been fully provided. You have a withdrawal period of 14 (fourteen) calendar days from the date we begin providing services to you to retract without having to justify your decision or incur additional fees for services
      the execution has not started on the day of the implementation of your right of withdrawal. You will be refunded the amount of your order on the method of payment indicated at the time of your order (except contrary instructions on your part).
      This refund will occur within a maximum period of 14 (fourteen) days from the day we are informed of the exercise of your right of withdrawal. You may exercise your right of withdrawal by sending an email to <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>&nbsp;</p>
    <p><strong>6. Intellectual Property and Use License</strong></p>
    <p>6.1 Intellectual Property</p>
    <p>The entire Site and its services including their structure, tree structure, graphics, object or source codes and their content (including text, graphics, images, photographs, videos, information, logos, button icons, software, audio files and
      others, databases) is the property of Gotta Apps, or their respective owners and is protected by intellectual property, including copyright and trademark law. The name "Gotta Yoga" and the "Gotta Yoga" logo are in particular protected trademarks
      of Gotta Apps.</p>
    <p>We reserve all rights to the Site that have not been expressly granted to you under these Terms. Accordingly, you are prohibited from using the Site for purposes other than those described below, in the absence of our express prior agreement.</p>
    <p>Any breach of the foregoing may incur your liability, without prejudice to our right to terminate your subscription unilaterally in accordance with Article 5.2. above.</p>
    <p>6.2. Use License</p>
    <p>Subject to compliance with these Terms, and based on the subscriptions subscribed, we authorize you to access and use the Site and its applicable services, and where applicable to download its content, exclusively for personal purposes. The
      licence thus granted to you is strictly personal, non-exclusive, limited and revocable in the event of non-compliance with these Terms. In particular, we draw your attention to the fact that no assignment of intellectual property rights can be
      inferred from your use of the Site and the Services, including with respect to the items that we authorize you to download pursuant hereto.</p>
    <p>Any professional or commercial use of the Site and services is strictly prohibited. Audio or video content accessible from the Site and services not explicitly indicated as downloadable may not be downloaded or copied by you.</p>
    <p>Except as specified below, you are strictly prohibited from performing or assisting a third party to do any or all of the following:</p>
    <ul>
      <li>Copying, storing, reproducing, transmitting, modifying, altering, disassembling, imitating, decompiling or disassembling the Site, including its services and content in any way, or creating derivative works from them;</li>
      <li>Using all or part of the Site, services or their content to create a tool or software that can be used to create software applications of any kind;</li>
      <li>Making available to the public in any manner (sale, rental, loan, distribution, reproduction, etc.) for free or for a fee, all or part of the Site, the services or their content;</li>
      <li>Altering all or part of the Website, services or their content or circumventing any technology used by Gotta Yoga or its licensors to protect all content accessible through the Site and its services;</li>
      <li>Bypassing security measures of the Site and access some or unauthorised functionality of the Site and Services;</li>
      <li>Bypassing territorial restrictions applied to the Site and the services; or more generally</li>
      <li>Using the Services in a manner that violates this License Agreement or other Terms,</li>
    </ul>
    <p>As an exception to the above, a limited amount of content will be allowed (and specified as such, if any for a limited time) to be shared by the user on their personal social networks (Facebook, Instagram, Twitter, etc.) and sites and personal internet
      pages (hereinafter the "Distribution Content"), Gotta Apps grants you a limited right to download, reproduce and distribute the Distribution Content on the Internet solely for the purpose of sharing and / or commenting on your social networks,
      sites and / or personal internet pages, as well as the following cumulative conditions: (i) the Distribution Content may not be modified under any circumstances, subject to the technical modifications necessary for its display and distribution
      through your computer systems and on the Internet (eg, a video format change or file size) that does not alter the content or quality of the Distribution Content, (ii) the Distribution Content may in no case may be shared, (iii) the
      distribution terms of the Distribution Content must comply with the additional terms that may be indicated on a case-by-case basis on the Site; Site, (iv) the Distribution Content must in no way be disseminated with any other content that you
      know to be false, inaccurate or misleading or that is or that encourages activity or conduct that is unlawful, harmful, threatening abusive, harassing, tortuous, defamatory, vulgar, obscene, pornographic, defamatory, disrespectful of the
      privacy of others, hateful, racist, or otherwise objectionable. This Distribution Content may contain gifs or cookies that allow us to collect information regarding the distribution and consumption of such content. For more information, please
      see our Privacy Policy.</p>
    <p>&nbsp;</p>
    <p><strong>7. Prohibited Uses of the Website and Services</strong></p>
    <p>7.1. Breaching the integrity, security and operation of the Site and services</p>
    <p>You may not upload, post, email, transmit or otherwise introduce on our platform any material or file containing computer viruses or any other computer code, files or programs designed to interrupt, damage, destroy or limit the functionality
      of any software or computer equipment or equipment directly or indirectly related to our services or our services themselves. You agree not to interfere with the servers or networks underlying or related to the Website or other Services or to
      violate the procedures, policies or regulations of the networks connected to our Website or our other services.</p>
    <p>You may not probe, scan or test the vulnerability of the Site or apps, violate security or authentication measures thereof, or attempt to trace or retrieve any information about any other user, visitor or members of the Site.</p>
    <p>You may not interfere or attempt to interfere with the access of any user, host or network, including, without limitation, the sending of a virus, overloading, flooding, spamming, or "mail bombing" of our services.</p>
    <p>You may not perform any action that imposes an unreasonable or disproportionate burden on the infrastructure of the Site, systems or networks, apps of Gotta Apps, or systems or networks connected to the Site and apps, or that is likely to
      interfere with the proper functioning of the Site or the apps, with any transaction made on them, or with the use of any other person.</p>
    <p>You may not use "deep-link", "page-scrape", "robot", "spider", or other automatic device, program, algorithm or methodology or any similar manual process or equivalent, to access, acquire copy or monitor any part of the Site and the apps, and
      you may not attempt in any way to reproduce or bypass the navigation structure or layout of the websites, applications, or any content to obtain or attempt obtain material, documents or information by means not available for this purpose on
      websites or applications.</p>
    <p>You are also prohibited from accessing or attempting to access any Gotta Yoga server, or any of the services offered on or through the Site and apps, by hacking, "password mining", or using other illegitimate means.</p>
    <p>7.2. Interactions on the Site and services</p>
    <p>In general, you agree not to behave in an objectionable manner while using our services or to use the Services for any unlawful, immoral or harmful purpose.</p>
    <p>In particular, you agree that messages posted online are not likely to, without this list being exhaustive, including:</p>
    <ul>
      <li>constitute a violation of the intellectual property rights of third parties; in this regard, the member undertakes not to reproduce or communicate to the public on the Site any protected elements under copyright, registered as a trademark
        or more generally protected by any other intellectual property right held by third parties without the prior consent of the owner (s) of such property rights;</li>
      <li>contain computer viruses that may interrupt, destroy or alter the functionality of the Site;</li>
      <li>apologize for crimes or to contain illegal, threatening, pedophile, pornographic, defamatory, obscene, hateful, racist, anti-Semitic, xenophobic, revisionist or contrary to public order or morality messages;</li>
      <li>violate privacy or respect for the dignity of third parties;</li>
      <li>incite violence, fanaticism, crime, suicide, hatred because of religion, race, sex, sexual orientation, ethnicity;</li>
      <li>harass other members;</li>
      <li>promote or encourage any activity or criminal enterprise;</li>
      <li>solicit and / or communicate passwords and / or personal information for commercial or illegal purposes;</li>
      <li>transmit e-mail chains, unsolicited massive mailings, instant messages, unwanted advertising messages or unsolicited letters; include advertisements and / or solicitations in order to offer products and / or services to the marketing
        through the Site;</li>
      <li>contain addresses or hypertext links to external sites whose content is contrary to the laws and regulations in force, which infringe the rights of third parties or which would be contrary to these conditions.</li>
    </ul>
    <p>The user undertakes not to resort to the automatic use of computer systems such as scripts to add members to their Profile and / or to send comments or messages.</p>
    <p>You are prohibited from manipulating headers or identifiers in order to disguise the origin of any message or transmission you send to Gotta Yoga on or through the Site, the apps, or any accessible service. You cannot pretend that you are or
      that you represent someone else or impersonate any other person or entity.</p>
    <p>You are solely responsible for any messages, content and information published in it while logged in to Site since in its capacity as host of the service here considered, GottaApps cannot be responsible for the content published by members on
      the Site and on which Gotta Apps has no power of control and supervision.</p>
    <p>&nbsp;</p>
    <p><strong>8. Service Availability</strong></p>
    <p>While we strive to provide you with the best possible service, we do not promise that services will always be available and will meet your needs, expectations and charges; we cannot guarantee that the services will be flawless. If an error
      occurs in the services, thank you to report it to <a href="mailto:info@gottayoga.app">info@gottayoga.app</a> and we will try to correct the fault within a reasonable time. If the need arises, we can suspend access to services as we try
      to fix the problem. We will not be liable to you if the services are unavailable for a period of time.</p>
    <p>Your access to services may be occasionally restricted to allow for repairs, maintenance or the installation of new facilities or services. We will try to restore services as soon as reasonably possible. In the event that services are not
      available, our usual deadlines and dates of ordering and cancellation normally apply; thank you to inform us of the changes relating to your order via our <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>The content of the catalog is subject to change. We reserve the right to remove our yoga and meditation sessions or programs from our catalog without notice and without recourse.</p>
    <p>&nbsp;</p>
    <p><strong>9. Hyperlinks</strong></p>
    <p>9.1. Links to other sites</p>
    <p>We may provide links to other sites or access services. You acknowledge that access to these sites or services is at your sole discretion and that these links are present for your information only. We have not reviewed, reviewed, or endorsed
      any of these websites or services. We may not be held responsible in any way for: (a) availability, (b) personal data protection practices, (c) content, advertising, products, goods or other materials or resources available on or from these
      sites / services, or (d) the use of what others are doing with these sites and services. We are not responsible for any damage, loss or crime caused or allegedly caused by, or in connection with, the use or trust you place in these sites or
      services.</p>
    <p>9.2. Link to the Website</p>
    <p>You may link to our homepage provided that you do so in a manner that is fair and lawful and does not damage or benefit our reputation, but you do not have the right to establish a link in such a way as to suggest any form of association,
      approval or endorsement on our part that does not exist. You must not link to our Site on a website that is not owned by you. Our Site and our other services must not be framed or encapsulated in another site; you may not link to any part of
      our Site or our other services, unless you have Gotta Apps' written permission to do so. We reserve the right to withdraw the authorisation via a written notice in case of use that damages our image or our reputation. The Site from which you
      link to us must comply in all respects with the content standards set forth in these Terms, and in particular Article 7.2 above. If you wish to make another use of the information available on our website or our other services as indicated
      above, please send your request to <a href="mailto:info@gottayoga.app">info@gottayoga.app</a></p>
    <p>&nbsp;</p>
    <p><strong>10. Limitation of liability</strong></p>
    <p>We put all reasonable means at our disposal to ensure quality access to the Site and the security of the data that you transmit to us via the Site, but we assume no obligation of result in this respect.</p>
    <p>We do not guarantee the continued operation of the Site which may be interrupted for maintenance reasons.</p>
    <p>We cannot be held responsible for any other malfunction or failure of the network or servers or any other technical malfunction beyond our reasonable control that would prevent or impair access to all or part of the Site, and in case of force
      majeure as defined by the applicable regulations.</p>
    <p>In particular, we draw your attention to the limitations and constraints specific to the Internet and the impossibility of completely guaranteeing the security of data exchanges. It is your responsibility to take all the necessary measures to
      protect yourself against unauthorised intrusions into your information system, in particular by securing your internet connection with a password and a security code or by using the software appropriate anti-virus.</p>
    <p>Furthermore, you are informed that the quality of the operation of the Site and in particular response times may vary depending on the personal settings of your connection station and your Internet access service. We assume no liability in
      this regard.</p>
    <p>Consequently, we cannot under any circumstances see our liability engaged, directly or indirectly, because of the malfunctions of the Site, including any loss of data saved by your account, caused by one or more of the technical
      characteristics inherent to the Internet, or any computer hardware or software used by you for any reason.</p>
    <p>Similarly, no malfunction of the Site, including any loss of data saved by your account, we cannot be blamed if such a malfunction would result from misuse of the Site by you, or the inadequacy of the hardware and computer software that you
      use in relation to the technical specifications referred to above.</p>
    <p>Information made available on the Site is for informational purposes only and does not constitute in any case an invitation to contract, advice or a recommendation of any kind.</p>
    <p>Consequently, the use of the information and content available on all or part of the Site does not in any way engage our liability for any reason whatsoever.</p>
    <p>Within the limits of applicable mandatory legal provisions, under no circumstances, including but not limited in case of negligence, Gotta Apps or its affiliates, suppliers, customers, or suppliers (collectively, the "protected entities") may
      be held liable for any indirect, special, incidental, consecutive, exemplary or punitive damages arising out of, or related directly or indirectly to the use or inability to use the services or the content, materials and the functions
      associated with it, your provision of information via the services, or loss of business or loss of sales, or errors, viruses or bugs contained in the services, even if such protected entity has been informed of the possibility of such damage.
      Under no circumstances may protected entities be held responsible for or in connection with any content posted, transmitted, exchanged or received by or on behalf of a user or other person or through the services. In no event shall the overall
      liability of the entities protected by you for any damages, losses and consequences of events and actions (contractual or tort, including, but not limited to, negligence or otherwise) arising out of such Conditions or your use of the services
      will not exceed, in total, the lesser of these amounts: (i) the amount you have, if any, paid to Gotta Apps for your use of the services in the previous 12 months or (ii) the sum of 100 (one hundred) euros.</p>
    <p>Nothing in this clause is likely to limit the liability of Gotta Apps or protected entities in cases of fraud, gross negligence or bodily injury.</p>
    <p>&nbsp;</p>
    <p><strong>11. Medical warning</strong></p>
    <p>Gotta Apps is a provider of online and mobile subscriptions to access content related to yoga, meditation, health and wellness. We are not a medical device, we do not provide medical advice. While there is evidence from third parties that
      yoga can help in the prevention and recovery process for a wide range of diseases, as well as in managing a number of performance and relational issues, Gotta Apps asserts, nor does it guarantee that this will be the case when using our services.
      All users with serious medical or psychological problems or requiring medical advice should consult their physician.</p>
    <p>All information and links from our health services, whether provided by Gotta Apps or contracted external service providers, are provided solely for your information and convenience.</p>
    <p>All advice or other items available in our services are intended solely for general information purposes. They are not meant to serve as a reference and substitute for professional medical advice based on your personal situation. The tips and
      other elements of the site are intended to help the relationship between you and your health care providers, but not to replace it. We cannot be held responsible for any action or action taken as a result of or after reading or hearing our
      advice and content or after using our services. In particular, to the extent permitted by law, we make no warranty as to the accuracy, completeness or relevance (for any purpose) of any notice or information published in the frame of our
      services.</p>
    <p>&nbsp;</p>
    <p><strong>12. Personal data</strong></p>
    <p>The personal data thus collected on the Site are processed in accordance with the law on the protection of individuals with regard to the processing of personal data. You have a right to access and rectify data
      concerning you, subject to having a legitimate interest, and you may, subject to justifying overriding and legitimate reasons, oppose their treatment by contacting <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>Gotta Apps reserves, in the event that the member would be responsible for an infringement of the legislation in force or an infringement of the rights of third parties, the right to provide, at the request of any legitimate authority
      (jurisdiction, administrative authority, police services), any information enabling or facilitating the identification of the offending member.</p>
    <p>For more information, please see our "Privacy Policy" which is available online and is an integral part of these Terms.</p>
    <p>&nbsp;</p>
    <p><strong>13. General provisions</strong></p>
    <p>13.1. Transfer</p>
    <p>Gotta Apps may transfer its rights and obligations under these Terms to any company, firm or person at any time if it does not materially affect your rights. You may not transfer your rights or obligations under these Terms to anyone other
      than Gotta Apps’ prior written consent.</p>
    <p>13.2. Guarantee and indemnities</p>
    <p>You agree to defend, indemnify and support Gotta Apps and its directors, officers, members, investors, officers, employees and agents from any claim, liability claim, costs and expenses, including reasonable attorney fees that could arise in
      any way from your use of the Services, from your publication or transmission of any message, content, information, software or other communications through our services, or from your violation of the law or these terms. Gotta Apps reserves the
      right, at its own expense, to assume the exclusive defense and control of any matter otherwise likely to require compensation by you, and in this case, you agree to cooperate with GottaApps’ defence of a such claim.</p>
    <p>13.3. No waiver</p>
    <p>If we delay the exercise or fail to exercise or enforce any right available to us under these Terms, such delay or failure does not constitute a waiver of such right or other rights under these terms.</p>
    <p>13.4. Force majeure</p>
    <p>We will not be liable to you for any lack of performance, unavailability or failure of services, or for any failure or delay on our part to comply with these conditions, in the event that this lack of performance, downtime or failure is due
      to a cause beyond our reasonable control.</p>
    <p>13.5. Interpretation</p>
    <p>In these Conditions, unless the context otherwise requires: (i) a phrase introduced by the words "including", "includes", "in particular", "for example" or any similar expression shall be construed as an illustration only and should not be
      interpreted as limiting the generality of all the preceding words; and (ii) the singular references include the plural and the masculine includes the feminine, and in each case vice versa.</p>
    <p>13.6. Written communications</p>
    <p>Applicable laws require that some of the information or communications we send you be in writing. When you use the Site and its services, you agree that communications with us are electronic. We will contact you by e-mail or provide
      you with information by posting on the Site notices on services. You agree to these means of electronic communications and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically
      have the same value as written communications.</p>
    <p>13.7. Proof / Archiving Evidence</p>
    <p>Computer data generated as a result of access and / or use of the Site (including, in particular, programs, connection data, files, records, operations and other items on computer or electronic media) (the "Data ") are likely to be kept and
      archived, within the limits provided for by law, by us within our computer system, under conditions that guarantee its integrity.</p>
    <p>The Data is authentic between you and us, and will be considered as evidence of communications between you and us.</p>
    <p>The Data constitutes literal proof within the meaning of the Civil Code Law and has the same probative force as a document that would be drawn up, received or kept in writing in paper form.</p>
    <p>Therefore, the Data may be validly produced as evidence and may be used against you in any claim or legal action with the same probative force as any document that would be drawn up, received or kept in writing in paper form, which you
      expressly recognise.</p>
    <p>We undertake not to contest the admissibility, opposability or probative value of the Data due to their electronic nature. You agree as well.</p>
    <p>You can have access to all Data on simple request addressed to us by simple mail or by e-mail.</p>
    <p>13.8. Divisibility</p>
    <p>If a court or a competent authority decides that any provision of these Terms is invalid, illegal or unenforceable to a certain extent, the provision will, to that extent only, be separate from other provisions, which will continue to be
      valid to the fullest extent permitted by law.</p>
    <p>13.9 Entire Agreement</p>
    <p>These Terms, as well as any additional terms and any other document expressly named in these terms represent the entire agreement between us and supersede all prior discussions, correspondence, negotiations, previous arrangements,
      understanding or agreement between us regarding the subject of these terms. We acknowledge that none of us relies on, a statement or warranty (whether made involuntarily or by negligence) that do not appear in these terms or the documents to
      which they refer Each of us agrees that our sole remedy with respect to such representations and warranties as set forth in this Agreement (if done unintentionally or negligently) will be breach of contract. Nothing in this section limits or
      excludes liability for fraud or theft.</p>
    <p>13.10. Applicable Law</p>
    <p>These Terms are subject to French Law.</p>
    <p>Subject to the applicable mandatory legal provisions, the courts of France shall have exclusive jurisdiction to hear any dispute relating to the interpretation, application and performance of these Terms, as well as any dispute related to the
      use of the Site.</p>
    <p>These Terms take effect from their last update on November 7, 2023.</p>
  </v-container>
</base-section>
</template>

<script>
export default {
  name: 'Terms',
}
</script>
