<template>
  <v-app id="inspire">
    <core-app-bar />
    <!-- <router-view></router-view> -->
    <core-view />
    <core-footer />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
    CoreAppBar: () => import('@/components/core/AppBar'),
    CoreFooter: () => import('@/components/core/Footer'),
    CoreView: () => import('@/components/core/View'),
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">

</style>