<template>
<base-section id="conditions" style="padding-top: 0!important">
  <v-container fluid class="pa-16 ma-2">
    <p>La société Gotta Apps SAS, société à responsabilité limitée de droit français, au capital social de EUR 20 000, dont le siège social est situé chez InExtenso, 24 chemin des Verrières, 69260 Charbonnières les Bains, immatriculée auprès du
      Registre de Commerce et des Sociétés de Lyon sous le numéro 801 905 266, et ayant pour numéro de TVA FR89801905266 (ci-après “Gotta Apps" ou "nous") s'engage à protéger et à respecter votre vie privée dans le cadre de votre utilisation de
      notre site Web gottayoga.app (le "Site"), de nos applications mobiles (les "Apps") et de nos autres produits et services (le Site, les Apps et les autres produits et services sont collectivement appelés ici les "Services").</p>
    <p>Cette politique de confidentialité définit comment nous gérons les renseignements et informations de nature à vous identifier, directement ou indirectement (les "Données Personnelles") lorsque vous utilisez nos Services. Merci de lire
      attentivement ce qui suit pour comprendre nos pratiques concernant vos Données Personnelles.</p>
    <p>&nbsp;</p>
    <p><strong>1. Les Données Personnelles que nous traitons</strong></p>
    <p>Celles que vous fournissez directement.</p>
    <p>En utilisant nos Services, notamment lorsque vous créez un compte, vous connectez à celui-ci, visualisez nos vidéos ou encore participez à nos jeux, vous êtes amenés à renseigner et ainsi à nous fournir certaines informations ayant la nature
      de Données Personnelles.</p>
    <p>Ces informations incluent, dans la mesure nécessaire et sans que cette énumération ne soit limitative :</p>
    <ul>
      <li>Les Données Personnelles nécessaires à l’inscription à nos Services, à savoir votre adresse email et mot de passe, et, pour bénéficier de nos services payants, vos coordonnées bancaires ;</li>
      <li>Vous pouvez également décider de nous fournir sur nos Services des informations complémentaires tels que votre prénom, votre nom de famille et numéro de téléphone (pour nos cours en direct uniquement). Contrairement aux informations précitées, ces informations
        complémentaires ne sont pas obligatoires afin de valider votre inscription aux Services. Elles permettent néanmoins de personnaliser notre relation ;</li>
      <li>Les Données Personnelles que vous nous fournissez en vue d’entrer en relation avec nous, notamment via le formulaire de contact accessible en ligne, telles que votre adresse email, le contenu de votre demande et les éventuelles pièces
        jointes fournies, votre numéro de téléphone, etc. ;Nous pouvons être amenés à vous demander des informations complémentaires lorsque vous nous signalez un problème concernant nos Services, en vue de la résolution de ce problème ;</li>
      <li>L’adresse IP, le modèle de l’appareil, le navigateur et la langue de l’appareil dont vous faites usage pour vous connecter aux Services ; Une copie de la correspondance (y compris par email) que vous êtes susceptible de nous adresser ;</li>
      <li>Le cas échéant, votre adresse e-mail en vue de votre inscription à nos newsletters. Si vous ne souhaitez pas recevoir d'e-mails marketing, suivez les instructions de désinscription incluses dans chaque email envoyé. Nous vous retirerons
        alors de notre liste d'envoi ;</li>
      <li>Le cas échéant, vos réponses aux sondages auxquels nous vous invitons à participer, ainsi que le contenu de vos publications sur notre Site. Ces informations sont susceptibles d’être utilisées dans le cadre de recherches et d’analyses du
        comportement utilisateur, afin d’améliorer nos Services ;</li>
      <li>Le cas échéant, les Données Personnelles que vous nous fournissez en vue de participer à un concours ou à une de nos promotions sponsorisées, afin de valider votre inscription ;</li>
      <li>Les détails des transactions que vous effectuez à travers nos Services et les commandes que vous faites via nos Services.</li>
      <li>Le détail de vos visites sur le Site et les Apps et liés à l'utilisation des Services, y compris, mais sans s'y limiter, les données de trafic, les données de localisation, les ressources auxquelles vous accédez, les weblogs et autres
        données de communication, si cela est nécessaire pour nos propres besoins de facturation ;</li>
      <li>Le temps passé sur les Services, y compris la liste des séances de yoga visionnés et vos préférences ;</li>
      <li>Les informations provenant de votre profil Facebook (email, prénom et nom uniquement) si vous choisissez de vous connecter à nos services grâce à Facebook.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>2. Celles que nous collectons automatiquement</strong></p>
    <p>a. Les Données Personnelles liées aux réseaux sociaux</p>
    <p>Lorsque vous vous connectez à nos Services via votre compte Facebook, nous collectons votre email, nom, prénom, tels que renseignés par vous lors de la création de votre compte Facebook. Nous
      aurons également aura accès à certaines des données de votre compte Facebook conformément aux conditions générales d’utilisation du réseau social concerné.</p>
    <p>b. Votre utilisation de nos Services</p>
    <p>Les fichiers journaux nous permettent d'enregistrer votre utilisation des Services. Notre équipe technique rassemble les informations du journal de fichier de tous nos utilisateurs, que nous utilisons pour apporter des améliorations à nos
      Services et nous informer sur la façon dont les utilisateurs utilisent les Services (par exemple nombre de séances de yoga réalisé, temps de pratique de yoga, historique des cours, etc.). Ces informations sont publiées sur votre compte
      utilisateur.</p>
    <p>Si vous recevez une version html de nos newsletters, nous allons être notifié de votre ouverture de la newsletter e-mail et nous allons sauvegarder cette information. Vos clics sur les liens dans la newsletter sont également enregistrés.
      Ceux-ci et les statistiques d'ouverture sont utilisés sous une forme agrégée pour nous donner une indication sur la popularité du contenu et pour nous aider à prendre des décisions sur le contenu et son formatage dans le futur.</p>
    <p>c. Cookies</p>
    <p>Lors de chacune de vos visites, nous sommes susceptibles de recueillir, conformément à la législation applicable, des informations relatives aux appareils sur lesquels vous utilisez nos Services ou aux réseaux depuis lesquels vous accédez à
      nos Services, tels que notamment vos adresses IP, données de connexion, types et versions de navigateurs internet utilisés, systèmes et plateformes d’exploitation, données concernant votre parcours de navigation sur notre Site et nos Apps, les
      erreurs de téléchargement, le contenu auquel vous accédez ou consultez, l’identifiant publicitaire de votre appareil, etc.</p>
    <p>Pour ce faire, nous avons notamment recours aux cookies.</p>
    <p>Qu'est-ce qu'un cookie ?</p>
    <p>Un cookie est un petit fichier texte enregistré et/ou lu par votre navigateur, stocké dans le répertoire cookies du disque dur de votre appareil (PC, ordinateur portable ou smartphone), et déposé par les sites internet que vous visitez. Il
      contient un identifiant unique et anonyme.</p>
    <p>Quels cookies utilisons-nous ?</p>
    <p>Lorsque nous fournissons des Services, nous voulons les rendre faciles à utiliser, simples, pratiques, utiles et fiables. Pour ce faire, nous utilisons plusieurs catégories de cookies :</p>
    <ul>
      <li>Des cookies absolument nécessaires, afin de vous permettre de vous déplacer sur le Site et les Apps et d’utiliser leurs fonctionnalités de base. Ces cookies sont indispensables pour l’utilisation de nos Services ;</li>
      <li>Des cookies de sécurité ;</li>
      <li>Avec votre consentement, des cookies d’authentification, qui vous dispensent d’avoir à fournir votre identifiant et mot de passe lors de chaque nouvelle connexion à nos Services ;</li>
      <li>Avec votre consentement, des cookies de personnalisation de l’interface utilisateur : ces cookies permettent de mémoriser les préférences d’un utilisateur (prénom, préférence linguistique par exemple) ;</li>
      <li>Avec votre consentement, des cookies d’analyse et de performance qui nous permettent de reconnaître et de compter le nombre de visiteurs sur notre Site et nos Apps et de collecter des informations sur la manière dont ceux-ci sont utilisés.
        Cela nous permet d’améliorer la façon dont notre Site et nos Apps fonctionnent ainsi que plus généralement le contenu de nos Services. Ces cookies nous permettent également de mesurer l’efficacité de nos efforts marketing ;</li>
      <li>Avec votre consentement, des cookies de partage sur les réseaux sociaux : lorsque vous partagez un contenu provenant de nos Services sur un réseau social, via un bouton de partage, un cookie sera déposé sur votre terminal si vous avez
        accepté ce type de traçage. Tant que vous n’avez pas cliqué sur le bouton de partage, puis cliqué une seconde fois sur le réseau social souhaité, aucun cookie ne sera déposé sur votre terminal.</li>
      <li>Avec votre consentement, des cookies publicitaires ou de ciblage pour enregistrer votre visite sur notre site, ainsi que les pages que vous avez consultées et les liens que vous avez suivis. Ces informations nous permettent d’afficher des
        publicités pertinentes en fonction de vos centres d’intérêts, de limiter le nombre d’affichages d’une même publicité et pour aider à mesurer l’efficacité des campagnes publicitaires. Elles peuvent être partagées avec des tiers, tels que les
        annonceurs, les agences de publicité ou les régies.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Les cookies seront effacés par nous passée une période de 13 mois à compter de leur date de dépôt.</p>
    <p>Placement de cookies par des tiers</p>
    <p>Les tiers auxquels nous faisons appel afin de connaître votre utilisation de nos Services ou à des fins marketing peuvent utiliser des cookies, que nous ne maîtrisons pas.</p>
    <p>Ce Site utilise notamment Facebook Analytics et Google Analytics. Par exemple Google Analytics installe des cookies pour nous aider à estimer avec précision le nombre de visiteurs sur le Site et les volumes d'utilisation des Services, ceci
      pour assurer que les Services sont disponibles quand vous le souhaitez et que votre expérience soit assez rapide. Pour plus d'informations sur la façon dont Google Analytics traite cette information, visitez <a href="https://www.google.com/analytics">www.google.com/analytics</a>.</p>
    <p>Nous utilisons également les services de Google Ads et Facebook Ads dans le cadre de nos efforts marketing. Ces services sont susceptibles d’installer des cookies sur votre appareil.</p>
    <p>De même, lorsque vous visitez une page avec du contenu hébergé par un tiers (par exemple, des vidéos YouTube) ou utilisez certains des liens vers des sites de réseaux sociaux (par exemple, le bouton "partager"), ces sites peuvent placer des
      cookies sur votre ordinateur.</p>
    <p>Nous ne contrôlons ni la manière dont ces tiers utilisent ces cookies, ni la durée pendant laquelle ils les conservent. Nous vous invitons à vérifier les politiques de confidentialité de ces sites tiers pour plus d'informations sur ce sujet.</p>
    <p>Comment gérer les cookies ?</p>
    <p>Lors de votre première visite sur le Site ou les Apps, nous vous donnons accès à la présente politique et vous demandons de faire un choix quant à l’acceptation ou au refus de l’utilisation de cookies tiers en vue de mesurer l’audience de
      notre Site ainsi que la performance de nos efforts marketing en ligne.</p>
    <p>Vous pourrez toujours modifier votre choix en nous contactant à l’adresse email <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>Les paramètres de votre navigateur vous permettent également de modifier les paramètres d’acceptation des cookies.</p>
    <p>Bien que votre navigateur fonctionne de façon à autoriser la création de cookies, vous pouvez le configurer de façon à ce qu'une fenêtre vous demande systématiquement confirmation avant qu'un site ne mette un cookie sur votre disque dur, de
      sorte que vous puissiez décider d'autoriser ou d'interdire le cookie. Alternativement, vous pouvez configurer votre ordinateur pour n'accepter aucun cookie. Nos services ne prennent pas en charge un navigateur qui est signalé comme "à ne pas
      tracer".</p>
    <p>Si vous choisissez de ne pas accepter le cookie, cela n'affectera pas votre accès à la majeure partie du contenu et des éléments disponibles par l'intermédiaire de nos Services.</p>
    <p>&nbsp;</p>
    <p><strong>3. Pourquoi utilisons-nous vos Données Personnelles?</strong></p>
    <p>Nous procédons aux traitements de vos Données Personnelles pour les finalités suivantes, lesquelles sont justifiées par l’exécution de nos obligations contractuelles et la satisfaction de vos demandes, ce qui constitue la base légale des
      traitements concernés :</p>
    <ul>
      <li>vous permettre d’accéder à nos Services et vous fournir les informations et Services demandés (incluant les cartes cadeau) ;</li>
      <li>vous fournir une assistance client ;</li>
      <li>percevoir vos paiements ;</li>
      <li>vous informer des modifications apportées à nos Services ;</li>
      <li>vous permettre d’utiliser les fonctionnalités interactives de nos Services si vous le souhaitez ;</li>
      <li>vous fournir un historique des cours de yoga réalisés par eux ainsi que des informations d’ordre statistique sur vos usages de nos Services ;</li>
      <li>vous envoyer, par email, des messages relatifs à l’utilisation de nos Services afin de vous accompagner dans votre pratique du yoga et vous motiver ;</li>
      <li>analyser les messages échangés par vous avec d’autres membres de notre communauté via le Site ou les Apps afin de s’assurer que nos conditions générales d’utilisation sont bien respectées.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Le traitement de vos Données Personnelles est également dans d’autres cas justifié par notre intérêt légitime qui constitue alors le fondement légal du traitement dans les cas suivants :</p>
    <ul>
      <li>améliorer et optimiser notre Site et nos Apps, notamment pour nous assurer de ce que l’affichage de nos contenus est adapté à votre ordinateur ou autre dispositif ;</li>
      <li>gérer notre Site et nos Apps et effectuer des opérations techniques internes dans le cadre de résolution de problèmes, analyse de données, de tests, recherches, analyses, études et sondages pour assurer la sécurité de notre Site et de nos
        Apps et en améliorer les caractéristiques) ;</li>
      <li>effectuer des statistiques de votre utilisation de nos Services afin de vous fournir des contenus adaptés à vos besoins et améliorer nos Services ;</li>
      <li>vous envoyer par email ou SMS, conformément aux dispositions légales applicables, des messages concernant des services similaires à ceux que vous avez utilisés ou vous transmettre des offres spéciales occasionnelles à leur sujet afin
        d’assurer la promotion de nos Services ;</li>
      <li>gérer les avis, incidents, réclamations et contentieux pour nous permettre de constater, exercer ou défendre nos droits en justice ;</li>
      <li>mesurer la performance de nos efforts marketing pour le développement et pérennisation de nos activités.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Vos Données Personnelles sont également traitées pour nous permettre de donner suite à vos demandes de droit d’accès, de rectification et d’opposition, ou portant sur vos autres droits à l’égard des Données Personnelles. Ce traitement est
      alors fondé sur le respect de nos obligations légales.</p>
    <p>Enfin, les traitements liés à certains cookies (voir point 2 ci-dessus) ou relatifs à la réception par email ou SMS d’informations de la part de tiers sont fondés sur votre consentement. Vous pouvez alors retirer celui-ci à tout moment alors
      qu’il s’agit de la seule base légale applicable.</p>
    <p>&nbsp;</p>
    <p><strong>4. À qui sont transmises vos Données Personnelles?</strong></p>
    <p>Les Données Personnelles sont traitées en interne par les personnes dûment habilitées, dans la limite de leurs attributions respectives.</p>
    <p>Elles sont également susceptibles d’être communiquées aux destinataires externes suivants :</p>
    <ul>
      <li>organismes financiers teneurs des comptes mouvementés ;</li>
      <li>autorités administratives et judiciaires compétentes, telles que les autorités fiscales, afin de permettre à Gotta Apps de remplir ses obligations légales en la matière, d’appliquer ou faire appliquer ses accords avec vous, ou pour protéger
        les droits, la propriété ou la sécurité de Gotta Apps, de nos clients et de tous tiers ;</li>
      <li>auxiliaires de justice et officiers ministériels dans le cadre de leur mission de recouvrement de créances ;</li>
      <li>sous-traitants (notamment prestataires informatiques) et prestataires de services externes (tels que auditeurs, conseils juridiques, fiduciaires, fournisseurs de solutions analytiques ou de services de paiement, sociétés et organisations
        spécialisées dans la protection contre la fraude et la réduction du risque d’impayé, etc.), dans la stricte mesure nécessaire et sous réserve de l’existence de garanties contractuelles propres à assurer la sécurité et la confidentialité des
        données ;</li>
      <li>Lorsque vous en faites la demande expresse (par exemple lorsque vous utilisez les méthodes d’authentification fournies par les réseaux sociaux ou souscrivez à un service fourni par un de nos partenaires, tel que Apple Health), nous pouvons
        être amenés à divulguer vos Données Personnelles à ce partenaire afin qu'il puisse vous fournir le service ou l’information sollicités. Il est de la responsabilité du tiers que vous autorisez à recevoir vos données de protéger ces
        informations conformément aux lois applicables, et ne les utiliser que pour fournir les services ou les renseignements que vous avez demandés ;</li>
      <li>Dans le cas où nous envisagerions de vendre ou d’acheter une activité ou des actifs, nous pourrons être amené, dans la stricte mesure nécessaire, à divulguer vos Données Personnelles au vendeur ou à l’acheteur potentiel d'une telle
        entreprise ou des actifs ;</li>
      <li>Si Gotta Apps ou la quasi-totalité de nos actifs sont acquis par un tiers, les Données Personnelles que nous détenons sur vous seront transmises audit repreneur.</li>
    </ul>
    <p>&nbsp;</p>
    <p>L'Application « Gotta Yoga » permet à l’Utilisateur d’ajouter des activités physiques afin de bénéficier d’informations concernant leur impact sur le suivi de ses objectifs et sur sa santé.</p>
    <p>L’Utilisateur a plusieurs possibilités pour renseigner une activité physique :</p>
    <p>
      <ul>
        <li>Synchroniser l’Application « Gotta Yoga » avec des Applications tierces :</li>
        <li>L’Utilisateur a la possibilité de lier l’Application « Gotta Yoga » avec des Applications tierces telles que l’Application « Santé » éditée par la Société APPLE, afin de synchroniser automatiquement ses activités physiques avec
          l’Application « Gotta Yoga ». Pour effectuer cette synchronisation, l’Utilisateur doit accepter le partage de donnée avec l’application Santé d’APPLE quand demandé (après un ajout de sport ou directement dans les paramètres de
          l’application). Si vous vous connectez à des Application Tierces comme Santé d’Apple, « Gotta Yoga » collectera et utilisera vos données personnelles provenant de ces applications tierces et en particulier les données de Santé
          que vous choisissez de synchroniser avec « Gotta Yoga ». « Gotta Yoga » n’utilise pas les données d’Apple Santé à des fins marketing, et ne partage pas ces données à des tierces.</li>
        <li>Renseigner manuellement une activité physique :</li>
        <li>L’Utilisateur peut également rentrer lui-même des activités physiques parmi celles contenues dans la base de données de l’Application « Gotta Yoga ».</li>
      </ul>
    </p>
    <p>Pour accéder à ce Service, l’Utilisateur doit accepter le partage de donnée depuis la page « Paramètres » de l’application.</p>
    <p>&nbsp;</p>
    <p><strong>5. Comment protégeons-nous vos Données Personnelles?</strong></p>
    <p>Les Données Personnelles recueillies par Gotta Apps sont traitées par elle, en qualité de responsable du traitement, dans le respect de la législation sur la protection des données applicable, notamment RGPD, ainsi que toute autre règlementation ultérieure).</p>
    <p>Gotta Apps s’engage à mettre en œuvre les mesures de sécurité techniques et organisationnelles de nature à assurer la protection des Données Personnelles contre les risques liés à l’usage des systèmes d’information, et notamment l’accès non
      autorisé à ces informations. Sauf en cas de divulgation de vos Données Personnelles telle que décrite au point 4 ci-dessus, nous ne fournissons pas vos Données Personnelles à une tierce partie sans votre consentement express.</p>
    <p>Toutes les informations que vous nous fournissez à travers nos Services sont stockées soit par les services Google, soit par les services Stripe pour les informations bancaires. Toutes les transactions de paiement seront cryptées en utilisant la technologie SSL. Lorsque nous vous avons
      donné (ou si vous avez choisi) un mot de passe qui vous permet d'accéder à certaines parties des Services, vous êtes responsable du maintien de ce mot de passe confidentiel. Nous vous demandons de ne pas partager un mot de passe avec
      quiconque. Malheureusement, la transmission d'informations via Internet n'est pas entièrement sécurisée. Bien que nous fassions de notre mieux pour protéger vos renseignements personnels, nous ne pouvons pas garantir la sécurité de vos
      informations transmises aux Services; toute transmission est à vos risques et périls.</p>
    <p>&nbsp;</p>
    <p><strong>6. Combien de temps conservons-nous vos Données Personnelles</strong></p>
    <p>Vos Données Personnelles sont conservées pendant une durée de 10 ans à compter de la fermeture de votre compte ou de votre dernière utilisation de nos Services si vous n’avez pas fermé votre compte, ce qui correspond à la durée de
      prescription applicable. Ce délai peut être prorogé au-delà pendant la durée des procédures judiciaires ou administratives en cours le cas échéant.</p>
    <p>Toutefois, dès fermeture de votre compte ou passé un délai de deux (2) ans à compter de votre dernière utilisation de nos Services si vous n’avez pas fermé votre compte, vos Données Personnelles sont simplement stockées à des fins d’archivage
      de manière sécurisée pour répondre à nos obligations légales ou, le cas échéant, nous permettre de nous défendre en justice. Elles ne sont pas communiquées à des tiers ou utilisées autrement.</p>
    <p>&nbsp;</p>
    <p><strong>7. Quels sont vos droits sur vos données personnelles?</strong></p>
    <p>Vous disposez du droit d’obtenir, sans frais :</p>
    <ul>
      <li>l’accès aux Données Personnelles vous concernant et leur rectification ;</li>
      <li>la confirmation que des Données Personnelles vous concernant sont ou ne sont pas traitées ;</li>
      <li>des informations portant au moins sur les finalités du traitement, sur les catégories de Données Personnelles sur lesquelles le traitement porte et les destinataires ou les catégories de destinataires auxquels les Données Personnelles sont
        communiquées ;</li>
      <li>la communication, sous une forme intelligible, des Données Personnelles faisant l’objet des traitements, ainsi que de toute information disponible sur l’origine des données.</li>
    </ul>
    <p>Vous disposez également des droits suivants, dans les limites prévues par les règles applicables et sous réserve de justifier de votre identité :</p>
    <p>
      <ul>
        <li>demander l’effacement de tout ou partie des données ou une limitation du traitement;</li>
        <li>vous opposer au traitement de vos données ;</li>
        <li>faire usage de votre droit à la portabilité des Données Personnelles.</li>
      </ul>
    </p>
    <p>Ces droits peuvent être exercés par email, adressé à l’adresse suivante : <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>. N’hésitez pas à nous contacter à cette adresse également pour toute précision complémentaire sur le contenu
      de vos droits ou la manière de les exercer.</p>
    <p>Vous pouvez accéder à vos Données Personnelles et les modifier à tout moment via votre compte en ligne.</p>
    <p>Vous disposez également de la possibilité de porter plainte, en cas de manquement(s) aux règles applicables en matière de protection des données personnelles, devant une autorité compétente.</p>
    <p>Vous avez le droit de nous demander de ne pas utiliser vos Données Personnelles à des fins de marketing pour vous adresser des informations sur nos propres Services. Vous pouvez exercer votre droit d'empêcher un tel traitement en suivant les
      instructions de l'opt-out dans le matériel de marketing que vous recevez ou en communiquant avec nous à tout moment par email à <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>, ou en retirant votre consentement directement sur votre
      interface utilisateur</p>
    <p>Les Services peuvent, de temps à autre, contenir des liens vers des sites Web de nos réseaux de partenaires, annonceurs et affiliés. Si vous suivez un lien vers un de ces sites, merci de noter que ces sites ont leurs propres politiques de
      confidentialité et que nous déclinons toute responsabilité quant à l’utilisation faite par ces sites des informations collectées lorsque vous cliquez sur ces liens. Nous vous invitons à prendre connaissance des politiques de confidentialité de
      ces sites avant de leur transmettre vos Données Personnelles.</p>
    <p>&nbsp;</p>
    <p><strong>8. Vie privée des enfants</strong></p>
    <p>Les Services sont conçus pour les personnes âgées de 18 ans et plus. Les enfants entre les âges de 13 et 18 ans (ou l'âge de la majorité dans leur pays de résidence) non émancipés (les "mineurs") peuvent utiliser les Services, mais
      l'utilisation par les mineurs doit être effectué uniquement sous la supervision, le contrôle et avec le consentement de leurs représentants légaux. Nous comptons sur les parents et les tuteurs pour s'assurer que les mineurs utilisent les
      services en comprenant leurs droits et responsabilités comme indiqués dans les conditions d'utilisation et de vente applicables aux Services (les "Conditions"). Si vous êtes un représentant légal d'un mineur et que vous donnez votre
      consentement à l'utilisation des Services par l'enfant dont vous avez la responsabilité légale, vous acceptez d'être lié par cette politique de confidentialité et les Conditions. Les enfants de moins de 13 ans ne sont pas autorisés à utiliser
      les Services. Si vous êtes sous l'âge de 13 ans, merci de ne pas tenter d'accéder ou d'utiliser les Services. Si vous croyez que nous avons pu recueillir des renseignements personnels d'un enfant de moins de 13 ans, s'il vous plait nous en
      aviser à <a href="mailto:info@gottayoga.app">info@gottayoga.app</a> afin que nous puissions supprimer ces informations.</p>
    <p>&nbsp;</p>
    <p><strong>9. Changements à notre politique de confidentialité</strong></p>
    <p>Gotta Apps se réserve le droit de modifier ou mettre à jour cette politique de confidentialité, ou tout autre de nos politiques ou pratiques, à tout moment, et informer les utilisateurs des Services en publiant la politique de confidentialité
      modifiée ou mise à jour sur la page d’accueil du Site ou de l’App en question. Toute modification entrera en vigueur immédiatement après cette publication. Votre utilisation continue des Services constitue votre accord de se conformer à la
      politique de confidentialité telle que modifiée. Dans certaines circonstances (par exemple, si nous souhaitons utiliser vos Données Personnelles pour des finalités différentes de celles indiquées dans notre politique de confidentialité au
      moment de la collecte), lorsque cela est nécessaire, nous vous en informerons personnellement et /ou solliciterons votre accord.</p>
    <p>&nbsp;</p>
    <p><strong>10. Contactez-nous</strong></p>
    <p>Vos questions, commentaires et demandes concernant cette politique de confidentialité sont les bienvenus et doivent être adressés à <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>Cette politique de confidentialité est effective et a été mise à jour le 7 novembre 2023.</p>
  </v-container>
</base-section>
</template>

<script>
export default {
  name: 'Confidentialite',
}
</script>
