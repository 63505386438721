<template>
<base-section id="conditions" style="padding-top: 0!important">
  <v-container fluid class="pa-16 ma-2">
    <p><strong>1. Informations légales et champ d'application</strong></p>
    <p>1.1. Informations légales</p>
    <p>Le site "Gotta Yoga" (ci-après le "Site"), qui est accessible à l'adresse <a href="https://gottayoga.app/">https://gottayoga.app</a>, via nos applications (ci-après les "apps") ou les sites internet de nos partenaires, est créé,
      développé et exploité par la société Gotta Apps SAS, société à responsabilité limitée de droit français, au capital sociale de EUR 20 000, dont le siège social est situé au chez InExtenso, 24 chemin des Verrières, 69260 Charbonnières les
      Bains, immatriculée auprès du Registre de Commerce et des Sociétés de Lyon sous le numéro <strong>801905</strong>, et ayant pour numéro de FR 89 801905266 (ci-après “Gotta Apps" ou "nous"). Nous sommes joignables par téléphone au +33 (0)6 37 64
      33 58 et par email à l'adresse <a href="mailto:info@gottayoga.app">info@gottayoga.app</a></p>
    <p>1.2. Champ d'application</p>
    <p>Les présentes conditions générales d'utilisation et de vente (les "Conditions") s'appliquent à tout visiteur ("vous" ou "l'utilisateur") du Site.</p>
    <p>Elles ont notamment pour objet de définir les modalités de mise à disposition du Site et de ses services du Site, ainsi que ses conditions d'utilisation.</p>
    <p>Tout accès et/ou utilisation du Site suppose l'acceptation et le respect de l'ensemble des Conditions. Lisez attention les Conditions avant de faire usage du Site ou de ses services. Si vous n'acceptez pas l'une quelconque des dispositions
      des présentes Conditions, n'utilisez pas le Site. Afin d'utiliser certains services du Site, il est possible que nous vous demandions d'accepter des conditions supplémentaires.</p>
    <p>1.3. Modification</p>
    <p>Nous nous réservons le droit de réviser et de modifier ces Conditions de temps à autre sans préavis notamment pour refléter les changements des conditions du marché affectant nos activités, les changements technologiques, les changements dans
      les méthodes de paiement, les changements dans les lois pertinentes et les exigences réglementaires et des changements dans les capacités de notre système. Votre utilisation continue des services après que de telles modifications soient
      faites, démontre votre acceptation de ces changements. Si votre achat des services est en cours, nous vous informerons en avance de toute modification apportée à ces termes et vous pourrez choisir d'annuler votre souscription à nos services,
      sans pénalité, avant que les conditions modifiées n'entrent en vigueur.</p>
    <p>&nbsp;</p>
    <p><strong>2. Configuration requise</strong></p>
    <p>L'utilisation du Site et de ses services nécessite une connexion Internet haut débit pour les ordinateurs personnels et un service Internet mobile (Edge, 3G, Wifi) pour les appareils portables. Ces connexions ne sont pas fournis par
      Gotta Apps, l'utilisateur doit donc d'abord souscrire individuellement à une connexion Internet haut débit et / ou une offre internet pour mobile afin d'utiliser Site et de ses services. Une connexion à Internet pour mobile dite de troisième
      génération (3G) est fortement recommandée.</p>
    <p>En tant qu'utilisateur, vous pouvez activer le service depuis un ordinateur personnel (PC ou MAC) avec un système d'exploitation et un navigateur compatible et depuis un appareil mobile compatible (hardware, système d'exploitation, navigateur
      compatibles). Le service est uniquement accessible par une seule connexion à la fois (ordinateur personnel ou un appareil portable compatible enregistré). Gotta Apps a les moyens techniques de vérifier le nombre de connexions simultanées sur le
      même compte et donc de détecter les tentatives de connexions multiples. Pour profiter du Site et de ses services via votre smartphone ou tout autre appareil, votre appareil doit satisfaire à certaines exigences relatives à leur système. Ces
      exigences peuvent être trouvés sur le Site et les appstores.</p>
    <p>&nbsp;</p>
    <p><strong>3. Accès et utilisation</strong></p>
    <p>3.1. Création d'un compte</p>
    <p>Afin d'accéder aux services du Site et souscrire les abonnements correspondants (tels que décrits ci-après), vous devez préalablement créer un compte via la rubrique du Site afférente en remplissant le formulaire d'inscription où vous ferez
      figurer, selon les indications qui lui seront demandées en ligne et dans les champs prévus à cet effet, les informations obligatoires pour la prise en compte de votre inscription, à savoir notamment une adresse e-mail valide, , en choisissant
      un mot de passe puis en validant son inscription, ou alternativement en utilisant le compte personnel Facebook dont vous êtes déjà titulaire en cliquant sur l'onglet "Valider", et en acceptant préalablement les présentes Conditions.</p>
    <p>A l'occasion de vos visites ultérieures, vous pourrez accéder aux services en saisissant votre adresse e-mail et votre mot de passe tels qu'enregistrés lors de votre inscription.</p>
    <p>Dans le cas d'un oubli de mot de passe, vous disposez d'une fonction pour le réinitialiser sur le Site.</p>
    <p>Chaque utilisateur ne peut créer qu'un seul compte. Si nous désactivons votre compte, vous ne chercherez pas en créer un autre sans notre autorisation.</p>
    <p>3.2. Identifiant et mot de passe</p>
    <p>L'identifiant et le mot de passe que vous avez choisis lors de la création de votre compte vous sont propres, personnels et confidentiels.</p>
    <p>Vous vous engagez à conserver secret votre identifiant et votre mot de passe et à ne pas le divulguer sous quelque forme que ce soit. La conservation, l'utilisation et la transmission de votre identifiant et de votre mot de passe s'effectuent
      sous votre entière et unique responsabilité. Particulièrement, vous êtes averti de l'insécurité inhérente à l'utilisation de la fonction de mémorisation automatique des données de connexion que votre système informatique peut permettre, et
      déclare assumer l'entière responsabilité de l'utilisation et des conséquences éventuelles d'une telle fonction.</p>
    <p>Toute utilisation de votre identifiant et de votre mot de passe sera présumée avoir été effectuée ou dûment autorisée par vous.</p>
    <p>Par conséquent, nous ne saurions en aucun cas être tenu responsable des conséquences préjudiciables résultant des utilisations illicites, frauduleuses ou abusives de votre identifiant et de votre mot de passe et de l'accès au Site par un
      tiers non expressément autorisé par vous qui résulteraient de votre faute ou de votre négligence.</p>
    <p>En cas de vol ou de détournement de votre identifiant et de votre mot de passe, il vous appartient de nous avertir immédiatement par lettre recommandée avec accusé de réception.</p>
    <p>En cas d'impossibilité de connexion avec votre identifiant et votre mot de passe, vous devez nous avertir sans délai par téléphone ou email.</p>
    <p>Vous ne pouvez pas transférer votre compte quiconque sans d'abord obtenir notre permission écrite, et vous interdisez d'usurper ou de tenter d'usurper l'identité d’autrui.</p>
    <p>3.3. Services</p>
    <p>Une fois votre compte créé, vous devenez membre de notre Site, ce qui vous donne accès à certaines sections, à certaines fonctionnalités et fonctions du site Web et de notre application qui ne sont pas disponibles pour les non-membres. Vous
      pouvez en outre également accepter aussi de recevoir des emails relatifs à des offres spéciales occasionnelles, des communications marketing, des communications relatives à votre cycle de vie d'utilisateur et des messages à caractère technique
      ou relatif à la qualité de service de notre plateforme. Vous pouvez facilement vous désabonner de ces courriels en suivant les instructions d'opt-out dans ces e-mails. Les adhésions et les abonnements à nos Services ne sont pas transférables
      et ne peuvent donc pas être vendus ou échangés ou transférés de quelque manière que ce soit. Les services auxquels vous pouvez accéder en tant que membre sont notamment les suivants :</p>
    <p>a) Pack Découverte</p>
    <p>Le Site propose à tout utilisateur ayant préalablement créé un compte un service de diffusion de cours de yoga. Quelques cours peuvent être visionnés gratuitement, sans engagement.</p>
    <p>b) Accès "Premium"</p>
    <p>Tout membre peut également, accéder à un service d'abonnement payant Premium lui donnant accès à tous les contenus, y compris et au-delà des séances d'essai gratuites. Tous les services de souscription donnent accès au site
      Web et aux applications. Vous pouvez devenir un Abonné par l'achat d'un abonnement aux services sur notre site web ou dans les applications, lorsque cela est autorisé par les partenaires de l'Appstore.</p>
    <p>Gotta Apps propose quatre types d'abonnement Premium :</p>
    <ol>
      <li>un abonnement Premium mensuel, renouvelable par tacite reconduction pour une durée identique sauf résiliation conformément aux termes des présentes Conditions,</li>
      <li>un abonnement Premium semi-annuel, renouvelable par tacite reconduction pour une durée identique sauf résiliation conformément aux termes des présentes Conditions, payable en un seul paiement lors de la souscription à l'abonnement</li>
      <li>un abonnement Premium annuel, renouvelable par tacite reconduction pour une durée identique sauf résiliation conformément aux termes des présentes Conditions, payable en un seul paiement lors de la souscription à l'abonnement</li>
      <li>un abonnement Premium d’une durée illimitée payable en un seul paiement lors de la souscription à l'abonnement (nous contacter pour ce type d’abonnement)</li>
    </ol>
    <p>Pour les besoins de nos abonnements mensuels, semi-annuels et annuels, un mois est fait de 30 jours civils, 6 mois est fait de 182 jours, une année est constituée de 365 jours.</p>
    <p>Dans le cadre de notre abonnement d’une durée illimitée, le terme « durée illimitée » désigne une période de cent (100) ans ou la période prenant fin à la date à laquelle Gotta Apps cesse de commercialiser les Services. Votre droit d’accès aux
      Services n’est pas transmissible ; en particulier, vos héritiers ne sont pas autorisés à accéder aux Services si vous décédez avant la fin de l’abonnement de cent (100) ans.</p>
    <p>Gotta Apps propose également d'accéder au service par l'intermédiaire de carte cadeau :</p>
    <ul>
      <li>Une carte cadeau est facturée et payée en une fois au moment de l'achat, et n'entraine pas de renouvellement automatique.</li>
      <li>Après avoir payé, l'acheteur accède à sa carte cadeau imprimable (en format PDF) sur le site ou par email.</li>
      <li>Durée d'abonnement : le bénéficiaire devient un Abonné pendant la durée d'abonnement inscrite sur la carte cadeau à partir de la date d'activation de la carte par le bénéficiaire. Pour s'activer, le bénéficiaire devra indiquer dans son
        compte de membre le numéro de la carte cadeau.</li>
      <li>Chaque carte est associée à un compte utilisateur unique, déclaré lors de l'activation, et ne peut-être transmis ou transféré ultérieurement. Son utilisation est exclusivement de la responsabilité du détenteur de la carte cadeau.</li>
      <li>Aucun remboursement n'est possible après activation du code inscrit sur la carte cadeau.</li>
      <li>Délai de validité : La durée de validité des cartes cadeaux est de 6 mois ou de 12 mois à compter de la date d'émission de la carte.</li>
      <li>L'acheteur confirme avoir vérifier que le bénéficiaire peut effectivement accéder au service de diffusion de cours de yoga de Gotta Yoga (version adéquate de smartphone, tablette, ordinateur, système d'exploitation, navigateur, ou app)</li>
    </ul>
    <p>Afin de souscrire les abonnements susvisés, ou d'acheter une carte cadeau, l'utilisateur doit choisir la formule choisie (abonnement mensuel, semi-annuel, annuel, carte cadeau), et renseigner les champs obligatoires du formulaire accessible
      sur le Site ou les apps, accepter les présentes Conditions et valider sa commande en cliquant sur le bouton "VALIDER ET PAYER". L'utilisateur recevra alors confirmation de sa commande par email.</p>
    <p>Notre obligation de vous fournir ce service premium ne commence (hors carte cadeau) que lorsque nous avons reçu confirmation du paiement de votre commande et, que nous vous avons confirmé votre achat par e-mail. Nous devrons confirmer votre
      commande quand elle a lieu et vous confirmer par email que vous avez accès au service conformément à l'abonnement acheté. Merci d'indiquer le numéro de commande dans toute correspondance ultérieure avec nous.</p>
    <p>Les prix en euros comprennent la TVA, sauf indication contraire.</p>
    <p>Nous pouvons à tout moment et de temps à autre, à notre seule discrétion, modifier les montants des abonnements, frais et taxes, ou ajouter de nouveaux abonnements, frais et taxes en lien avec tous nos services. Nous vous informerons par
      e-mail au moins 30 (trente) jours civils avant tout changement. Si vous n'acceptez pas le changement, vous pouvez résilier votre abonnement dans vos paramètres de compte ou par courriel <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>3.4. Garanties</p>
    <p>En créant un compte sur le Site et en utilisant les services, vous certifiez, garantissez et vous engagez à ce que :</p>
    <ul>
      <li>les informations que vous fournissez vous sont personnelles, sont exactes et que vous n'utilisez pas les informations personnelles d'autrui sans autorisation,</li>
      <li>vous êtes légalement capable de conclure des engagements juridiques;</li>
      <li>vous êtes âgé d'au moins 13 ans et si âgé de moins de 18 ans (ou de l'âge de la majorité selon la loi qui vous est applicable), vous créez votre compte et vous engagés sous la direction, la supervision et avec le consentement de vos
        représentants légaux;</li>
      <li>vos informations personnelles seront mises à jour si nécessaire.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>4. Paiement</strong></p>
    <p>4.1. Moyens de paiement</p>
    <p>Plusieurs moyens de paiement sont proposés sur le site (carte Visa ou MasterCard de crédit, PayPal). Sur les mobiles ou tablette sous IOS, le moyen de paiement proposé est iTunes d'Apple. Sur les mobiles ou tablette sous Android, le moyen de
      paiement proposé est Google Play Store Pay. Il est précisé que lorsque l'utilisateur communique à Gotta Apps ses coordonnées bancaires, celles-ci ne sont pas conservées par Gotta Apps.</p>
    <p>Afin d'optimiser la sécurité des transactions, Gotta Apps a choisi les systèmes de paiement des sociétés Paypal et Stripe, ainsi que de iTunes (Apple) pour les mobiles sous IOS et Play Store (Google) pour les mobiles sous Android. Les
      garanties accordées par Gotta Apps au titre de la sécurité des transactions sont identiques à celles obtenues respectivement par Gotta Apps des éditeurs des systèmes de paiement précités.</p>
    <p>À défaut d'éléments contraires apportés par l'Abonné, les registres informatisés, conservés dans les systèmes informatiques de Gotta Apps et de ses partenaires dans des conditions raisonnables de sécurité, seront considérés comme les preuves
      des communications, des commandes, des validations et des paiements intervenus entre l'Abonné et Gotta Apps.</p>
    <p>4.2. Modalités de paiement</p>
    <ul>
      <li>Abonnement mensuel : les abonnements mensuels sont payables par prélèvement automatique récurrent mensuel sur le mode de paiement choisi. Le premier paiement s'effectuera d'avance lors de la souscription à l'abonnement puis à chaque mois,
        par prélèvement automatique sur le mode de paiement fourni jusqu'à résiliation de l'abonnement, que la résiliation soit à son initiative ou à celle de Gotta Apps.</li>
      <li>Abonnement semi annuel : les abonnements semi annuels sont payables par prélèvement automatique récurrent semi-annuel sur le mode de paiement choisi. Le premier paiement s'effectuera d'avance lors de la souscription à l'abonnement puis à
        chaque date anniversaire de la souscription, par prélèvement automatique sur le mode de paiement fourni jusqu'à résiliation de l'abonnement, que la résiliation soit à son initiative ou à celle de Gotta Apps</li>
      <li>Abonnement annuel : les abonnements annuels sont payables par prélèvement automatique récurrent annuel sur le mode de paiement choisi. Le premier paiement s'effectuera d'avance lors de la souscription à l'abonnement puis à chaque date
        anniversaire de la souscription, par prélèvement automatique sur le mode de paiement fourni jusqu'à résiliation de l'abonnement, que la résiliation soit à son initiative ou à celle de Gotta Apps</li>
      <li>Abonnement illimitée : Dans le cadre d'un achat unique d’un abonnement illimité, l'acheteur s'engage à régler la totalité du montant à la commande.</li>
      <li>Carte Cadeau : Dans le cadre d'un achat unique sans abonnement (carte cadeau d'une certaine durée : 6 mois, 1 an ...) l'acheteur s'engage à régler la totalité du montant à la commande.</li>
    </ul>
    <p>Aucun remboursement partiel (correspondant à une partie de la période) ne sera admis en cas de résiliation unilatérale sans motif en cours de période.</p>
    <p>4.3. Autorisation de prélèvement</p>
    <p>Hors carte cadeau, vous autorisez Gotta Apps et le prestataire de paiement concerné à débiter le même moyen de paiement utilisé pour la souscription de l'abonnement initial lors des renouvellements d'abonnement applicable par En cas de
      reconduction tacite, le tarif alors en vigueur à la date du renouvellement pour l'abonnement concerné sera pleinement applicable. Vous vous engagez à aviser rapidement Gotta Apps de toute modification ou changement de mode de paiement que vous
      avez fourni, car dans cette attente, tous les abonnements seront suspendus. Vous êtes responsable de tous les éventuels frais applicables, y compris les taxes applicables, et de tous les abonnements que vous avez achetés. Dans l'hypothèse où
      nous ne pourrions prélever à l'échéance convenue le montant de votre abonnement, vous nous restez néanmoins redevable des échéances précédentes et nous nous réservons tous droits et actions à cet égard.</p>
    <p>&nbsp;</p>
    <p><strong>5. Réclamation, résiliation et droit de rétractation</strong></p>
    <p>5.1. Réclamation</p>
    <p>Vous pouvez adresser vos réclamations par email à l'adresse : <a href="mailto:info@gottayoga.app">info@gottayoga.app</a></p>
    <p>5.2. Résiliation de votre part</p>
    <p>Vous pouvez à tout moment résilier votre abonnement mensuel, semi annuel ou annuel en accédant aux paramètres de votre compte sur le Site en respectant un délai de préavis d'au moins 48 (quarante-huit) heures avant l'échéance en cours. La
      résiliation sera effective à la fin de la période, mensuelle, semi-annuelle ou annuelle, en cours.</p>
    <p>5.3. Résiliation par nous</p>
    <p>Nous pouvons suspendre ou résilier, sans préavis ni recours aux Tribunaux, votre utilisation de nos services à la suite d'une fraude ou de manquement à une obligation découlant de ces Conditions, y incluant sans limitation, le défaut de
      possibilité de prélever le montant de votre abonnement à l'échéance convenue, la copie non autorisée ou le téléchargement de notre contenu audio ou vidéo à partir du Site ou des apps ou de toute violation de la licence d'utilisation limitée
      que nous vous concédons dans le cadre de votre accès à nos services.</p>
    <p>5.4. Droit de rétractation</p>
    <p>Vous reconnaissez que la fourniture des services commence à la réception de l'email de confirmation de votre commande et que vous ne
      pouvez exercer votre droit de rétractation une fois que les services vous auront été pleinement fournis. Vous disposez d'un délai de rétractation de 14 (quatorze) jours calendrier à compter de la date à laquelle nous commençons à vous fournir
      les services pour vous rétracter sans avoir à motiver votre décision ni encourir de frais supplémentaires à l'égard des services dont l'exécution n'a pas commencé au jour de la mise en œuvre de votre droit de rétractation. Vous serez remboursé
      du montant de votre commande sur le mode de paiement indiqué lors de votre commande (sauf instructions contraires de votre part). Ce remboursement interviendra dans un délai maximum de 14 (quatorze) jours à compter du jour où nous sommes
      informés de l'exercice de votre droit de rétractation. Vous pouvez exercer votre droit de rétractation en adressant un email à <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>&nbsp;</p>
    <p><strong>6. Propriété intellectuelle et licence d'utilisation</strong></p>
    <p>6.1. Propriété intellectuelle</p>
    <p>L'ensemble du Site et de ses services y incluant leur structure, arborescence, graphisme, codes objet ou source ainsi que leur contenu (notamment textes, graphiques, images, photographies, vidéos, informations, logos, icônes-boutons,
      logiciels, fichiers audio et autres, bases de données) est la propriété de Gotta Apps, ou de leurs propriétaires respectifs et est protégé par la propriété intellectuelle, notamment par le droit d'auteur et le droit des marques. La dénomination
      "Gotta Yoga" et le logo "Gotta Yoga" sont notamment des marques protégées de Gotta Apps.</p>
    <p>Nous nous réservons tout droit sur le Site qui ne vous serez pas expressément concédé aux termes des présentes Conditions. En conséquence, vous vous interdisez d'utiliser le Site à d'autres fins que celles visées ci-dessous, en l'absence de
      notre accord exprès préalable.</p>
    <p>Tout manquement à ce qui précède est susceptible d'engager votre responsabilité, sans préjudice de notre droit de résilier unilatéralement votre abonnement conformément aux termes de l'article 5.2. ci-dessus.</p>
    <p>6.2. Licence d'utilisation</p>
    <p>Sous réserve du respect des présentes Conditions, et en fonction des abonnements souscrits, nous vous autorisons à accéder et à utiliser le Site et ses services applicables, et le cas échéant de télécharger son contenu, exclusivement à des
      fins personnelles. La licence qui vous est ainsi concédée est ainsi strictement personnelle, non exclusive, limitée et révocable en cas de non-respect des présentes Conditions. Nous attirons en particulier votre attention sur le fait qu'aucune
      cession de droit de propriété intellectuelle ne peut être inféré de votre utilisation du Site et des services, y compris à l'égard des éléments que nous vous autorisons à télécharger en application des présentes .</p>
    <p>Tout usage professionnel ou commercial du Site et des services est strictement interdit. Les contenus audio ou vidéo accessible à partir du Site et des services non explicitement indiqués comme téléchargeables ne peuvent être téléchargés ou
      copiés par vos soins.</p>
    <p>Sauf exceptions spécifiées ci-après, il vous est strictement interdit d'effectuer ou d'aider un tiers à effectuer tout ou partie des actions suivantes :</p>
    <ul>
      <li>Copier, stocker, reproduire, transmettre, modifier, altérer, désassembler, imiter, décompiler ou désassembler le Site, y compris ses services et son contenu de quelque manière, ou créer des œuvres dérivées à partir de ceux-ci;</li>
      <li>Utiliser tout ou partie du Site, des services ou de leur contenu pour créer un outil ou logiciel qui peut être utilisé pour créer des applications logicielles de quelque nature que ce soit;</li>
      <li>Mettre à la disposition du public de quelque manière que ce soit (cession, location, prêt, diffusion, reproduction, etc.), à titre gratuit ou onéreux, tout ou partie du Site, des services ou de leur contenu ;</li>
      <li>Altérer tout ou partie du Site, des services ou de leur contenu ou contourner toute technologie utilisée par Gotta Apps ou par ses concédants pour protéger tout le contenu accessible par le Site et ses services;</li>
      <li>Contourner les mesures de sécurité du Site et accéder à une partie ou une fonctionnalité non autorisée du Site et des services ;</li>
      <li>Contourner les restrictions territoriales appliquées au Site et aux services; ou plus généralement</li>
      <li>Utiliser les services d'une manière qui viole cet accord de licence ou les autres Conditions,</li>
    </ul>
    <p>Par exception à ce qui précède, une quantité limitée de contenu sera autorisé (et précisé comme tel, le cas échéant pendant une durée limitée) à être partagé par l'utilisateur sur ses réseaux sociaux personnels (Facebook, Twitter, etc) et
      sites et pages internet personnels (ci-après le "Contenu de distribution"), ,, Gotta Apps vous accorde un droit limité pour télécharger, reproduire et distribuer le Contenu de distribution sur Internet uniquement aux fins de partage et/ou
      commentaires sur vos réseaux sociaux, sites et/ou pages internet personnels, ainsi qu'aux conditions cumulatives suivantes : (i) le Contenu de distribution ne peut en aucun cas être modifié, sous réserve des modifications techniques
      indispensables à son affichage et sa distribution à travers vos systèmes informatiques et sur Internet (par exemple un changement de format vidéo ou la taille du fichier) n'altérant ni le fond ni la qualité du Contenu de distribution, (ii) le
      Contenu de distribution ne peut en aucun cas être partagé en dehors de la durée susceptible d'être indiquée sur le Site, (iii) les conditions de partage du Contenu de distribution doivent être conformes aux conditions additionnelles
      susceptibles d'être indiquées au cas par cas sur le Site , (iv) le Contenu de distribution ne doit en aucun cas être diffusé avec un un autre contenu que vous savez être faux, inexacte ou trompeur ou qui est, ou qui encourage l'activité ou des
      comportements qui sont illégaux, nuisibles, menaçants, abusifs, harcelants, tortueux, diffamatoires, vulgaires, obscènes, pornographiques, diffamatoires, irrespectueux de la vie privée d'autrui, haineux, raciste, ou autrement répréhensibles.
      Ce Contenu de distribution peut contenir des gifs ou des cookies qui nous permettent de recueillir des informations concernant la distribution et la consommation de ces contenus. Pour plus d'informations, veuillez consulter notre Politique de
      confidentialité.</p>
    <p>&nbsp;</p>
    <p><strong>7. Utilisations interdites du Site et des services</strong></p>
    <p>7.1. Atteinte à l'intégrité, la sécurité et au fonctionnement du Site et des services</p>
    <p>Vous vous interdisez de télécharger, publier, envoyer par courriel, transmettre ou introduire sur notre plateforme tout matériel ou fichier qui contient des virus informatiques ou tout autre code informatique, fichiers ou programmes conçus
      pour interrompre, abimer, détruire ou limiter la fonctionnalité de tout logiciel ou matériel informatique ou équipement lié directement ou indirectement avec nos services ou de nos services eux-mêmes. Vous acceptez de ne pas interférer avec
      les serveurs ou les réseaux sous-jacents ou connexes au site Web ou aux autres services ou de ne pas enfreindre les procédures, politiques ou règlements des réseaux connectés à notre site Web ou à nos autres services.</p>
    <p>Il vous est interdit de sonder, scanner ou tester la vulnérabilité du Site ou des apps, de violer les mesures de sécurité ou d'authentification de ceux-ci., ou encore de chercher à tracer, ou récupérer toute information sur tout autre
      utilisateur ou visiteur ou membres du Site.</p>
    <p>Vous ne pouvez en aucun cas interférer ou tenter d'interférer avec l'accès de tout utilisateur, hôte ou réseau, y compris, sans limitation, l'envoi d'un virus, l'"overloading", le "flooding", le spamming, ou du "mail bombing"de nos services.</p>
    <p>Vous vous interdisez toute action qui impose une charge déraisonnable ou disproportionnée sur l'infrastructure des Site, systèmes ou réseaux, des Apps de Gotta Apps, ou des systèmes ou réseaux connectés au Site et apps, ou qui est de nature à
      interférer avec le bon fonctionnement du Site ou des apps, avec toute transaction effectuée sur ceux-ci, ou avec l'utilisation de toute autre personne.</p>
    <p>Vous vous interdisez d'utiliser de "deep-link", de "page-scrape", "robot", "spider", ou autre dispositif automatique, programme, algorithme ou méthodologie ou tout processus manuel similaire ou équivalent, pour accéder, acquérir, copier ou
      surveiller toute partie du Site et des apps, , ou vous ne tenterez en aucune manière de reproduire ou de contourner la structure de navigation ou la présentation des sites Web, les applications, ou de tout contenu, d'obtenir ou de tenter
      d'obtenir du matériel, des documents ou informations par des moyens non disponibles à cet effet sur les sites Web ou les applications.</p>
    <p>Il vous est en outre interdit d'accéder ou de tenter d'accéder à n'importe quel serveur Gotta Yoga, ou à l'un des services offerts sur ou par l'intermédiaire du Site et des apps, par piratage, "password mining", ou utilisation d'autres moyens
      illégitimes.</p>
    <p>7.2. Interactions sur le Site et les services</p>
    <p>De manière générale, vous vous engagez à ne pas vous conduire d'une manière répréhensible lorsque vous utilisez nos services, ou de ne pas utiliser les services à des fins illégales, immorales ou nuisibles.</p>
    <p>Plus particulièrement, vous vous engagez à ce que les messages mis en ligne ne soient pas de nature à, sans que cette liste ne présente un caractère exhaustif, notamment :</p>
    <ul>
      <li>constituer une violation des droits de propriété intellectuelle de tiers ; à cet égard le membre s'engage à ne pas reproduire ni communiquer au public sur le Site des éléments protégés au titre du droit d'auteur, déposés à titre de marque
        ou plus généralement protégés par tout autre droit de propriété intellectuelle détenu par des tiers sans le consentement préalable du ou des détenteurs de tels droits de propriété;</li>
      <li>contenir des virus informatiques de nature à interrompre, détruire ou altérer les fonctionnalités du Site ;</li>
      <li>faire l'apologie de crimes ou contenir des messages illégaux, menaçants, ou à caractère pédophile, pornographique, diffamatoire, obscène, haineux, raciste, antisémite, xénophobe, révisionniste ou contraire à l'ordre public ou aux bonnes
        mœurs ;</li>
      <li>porter atteinte à la vie privée ou au respect de la dignité des tiers ;</li>
      <li>inciter à la violence, au fanatisme, au crime, au suicide, à la haine en raison de la religion, de la race, du sexe, de l'orientation sexuelle, de l'ethnie ;</li>
      <li>harceler les autres membres ;</li>
      <li>favoriser ou encourager toute activité ou entreprise criminelle ;</li>
      <li>solliciter et/ou communiquer des mots de passe et/ou des informations personnelles à des fins commerciales ou illégales ;</li>
      <li>transmettre des chaines d'e-mails, de mailings massifs non sollicités, de messages instantanés, de messages publicitaires non souhaités ou de courriers non sollicités ; comporter des annonces publicitaires et/ou sollicitations afin de
        proposer des produits et/ou services à la commercialisation par l'intermédiaire du Site ;</li>
      <li>contenir des adresses ou des liens hypertextes renvoyant vers des sites extérieurs dont le contenu est contraire aux lois et règlements en vigueur, qui portent atteinte aux droits des tiers ou qui seraient contraires aux présentes
        conditions.</li>
    </ul>
    <p>L'utilisateur s'engage à ne pas recourir à l'utilisation automatique de systèmes informatiques tels que des scripts en vue d'ajouter des membres à son Profil et/ou d'envoyer des commentaires ou des messages.</p>
    <p>Vous vous interdisez de manipuler les "headers"ou les identificateurs afin de dissimuler l'origine de tout message ou transmission que vous envoyez à Gotta Yoga sur ou via le Site, les apps, ou tout service y accessible. Vous ne pouvez pas
      prétendre que vous êtes, ou que vous représentez, quelqu'un d'autre ou usurper l'identité de toute autre personne ou entité.</p>
    <p>Vous êtes seul responsable des messages, contenus et informations publiés par ses soins sur le Site dès lors qu'en sa qualité d'hébergeur du service ici considéré, Gotta Apps ne saurait être responsable du contenu publiés par les membres sur
      le Site et sur lesquels Gotta Apps n'exerce aucun pouvoir de contrôle et de surveillance.</p>
    <p>&nbsp;</p>
    <p><strong>8. Disponibilité des services</strong></p>
    <p>Bien que nous nous efforçons de vous offrir le meilleur service possible, nous ne faisons pas la promesse que les services seront toujours disponibles et répondront à vos besoins, attentes et à la charge; nous ne pouvons garantir que les
      services seront sans défaut. Si une erreur se produit dans les services, merci de nous le signaler à <a href="mailto:info@gottayoga.app">info@gottayoga.app</a> et nous essaierons de le corriger la faute dans des délais raisonnable. Si le
      besoin s'en fait sentir, nous pouvons suspendre l'accès aux services alors que nous essayons de régler le problème. Nous ne pourrons pas être tenus responsables envers vous si les services ne sont pas disponibles pour une période de temps.</p>
    <p>Votre accès aux services peut être occasionnellement restreint pour permettre des réparations, l'entretien ou la mise en place de nouvelles installations ou services. Nous essaierons de rétablir les services dès qu'il sera raisonnablement
      possible. Dans le cas où les services ne sont pas disponibles, nos délais et dates habituels de commandes et d'annulation s'appliquent normalement; merci de nous informer des changements relatifs à votre commande via notre email <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>Le contenu du catalogue est susceptible d'évolution. Nous nous réservons la possibilité de supprimer, sans préavis et sans recours de votre part, nos séances de yoga ou programmes dans notre catalogue.</p>
    <p>&nbsp;</p>
    <p><strong>9. Liens hypertextes</strong></p>
    <p>9.1. Lien vers d'autres sites</p>
    <p>Il est possible que nous vous fournissions des liens vers d'autres sites ou services d'accès. Vous reconnaissez que l'accès à ces sites ou services est à votre seule discrétion et que ces liens ne sont présents que pour votre information
      seulement. Nous n'avons pas analysé, revu, ni ne soutenons aucun de ces sites Web ou services. Nous ne pouvons pas être tenus responsables d'aucune façon pour: (a) la disponibilité, (b) les pratiques de protection des données personnelles, (c)
      le contenu, la publicité, les produits, les biens ou d'autres matériaux ou de ressources disponibles sur ou à partir de ces sites/ services, ou (d) l'usage de ce que d'autres font de ces sites et services. Nous ne sommes pas responsables de
      tout dommage, perte ou délit causé ou prétendument causé par, ou en relation avec l'utilisation ou avec la confiance que vous accordez à ces sites ou services.</p>
    <p>9.2. Lien vers le Site</p>
    <p>Vous pouvez créer un lien vers notre page d'accueil, à condition que vous le fassiez d'une manière qui est juste et légale et qui n'endommage pas notre réputation ou en profite, mais vous n'avez pas le droit d'établir un lien de telle manière
      à suggérer une quelconque forme d'association, d'approbation ou d'endossement de notre part qui n'existe pas. Vous ne devez pas créer un lien vers notre Site sur un site Web qui n'est pas détenue par vous. Notre Site et nos autres services ne
      doivent pas être encadrés ou encapsulés dans un autre site; vous ne pouvez pas non plus créer un lien vers n'importe quelle partie de notre Site ou de nos autres services, sauf si vous avez la permission écrite de Gotta Apps de le faire. Nous
      nous réservons le droit de retirer l'autorisation via un avis écrit en cas d'usage portant atteinte à notre image ou à notre réputation. Le Site à partir duquel vous liez le notre doit se conformer en tous points aux normes de contenu définies
      dans les présentes Conditions, et notamment à l'article 7.2 ci-dessus. Si vous souhaitez faire une autre utilisation des informations disponibles sur notre site web ou nos autres services que celle indiquée ci-dessus, merci d'adresser votre
      demande à <a href="mailto:info@gottayoga.app">info@gottayoga.app</a></p>
    <p>&nbsp;</p>
    <p><strong>10. Limitation de responsabilité</strong></p>
    <p>Nous mettons tous les moyens raisonnables à notre disposition pour assurer un accès de qualité au Site ainsi que la sécurité des données que vous nous transmettez via le Site, mais n'assumons aucune obligation de résultat à cet égard.</p>
    <p>Nous ne garantissons pas le fonctionnement continu du Site qui peut notamment être interrompu pour des raisons de maintenance.</p>
    <p>Nous ne pouvons être tenus responsable de tout autre dysfonctionnement ou défaillance du réseau ou des serveurs ou de tout autre dysfonctionnement technique échappant à notre contrôle raisonnable qui empêcherait ou altérerait l'accès à tout
      ou partie du Site, ainsi qu'en cas de force majeure telle que définie par la réglementation applicable.</p>
    <p>Nous attirons plus particulièrement votre attention sur les limitations et contraintes propres au réseau internet et, l'impossibilité de garantir de manière totale la sécurisation des échanges de données. Il vous appartient à ce titre de
      prendre toutes les mesures nécessaires pour vous protéger contre les intrusions non autorisées au sein de votre système d'information, notamment en sécurisant votre connexion internet par un mot de passe et un code de sécurité ou en utilisant
      les logiciels anti-virus appropriés.</p>
    <p>Par ailleurs, vous êtes informés que la qualité du fonctionnement du Site et notamment des temps de réponse sont susceptibles de varier en fonction des paramètres personnels de votre poste de connexion et de votre service d'accès au réseau
      Internet. Nous n'assumons aucune responsabilité à cet égard.</p>
    <p>En conséquence, nous ne saurions en aucun cas voir notre responsabilité engagée, directement ou indirectement, du fait des dysfonctionnements du Site, y incluant toute perte de données sauvegardées par sur votre compte, causés par une ou
      plusieurs des caractéristiques techniques inhérentes au réseau Internet, ou au matériel informatique ou aux logiciels utilisés par vos soins, et ce pour quelque raison que ce soit.</p>
    <p>De même, aucun dysfonctionnement du Site, y incluant toute perte de données sauvegardées par sur votre compte, ne pourra nous être reproché dès lors qu'un tel dysfonctionnement résulterait d'une mauvaise utilisation du Site par vos soins, ou
      de l'inadéquation du matériel et des logiciels informatiques que vous utilisez au regard des spécifications techniques visées ci-dessus.</p>
    <p>Les informations mises à disposition sur ce site le sont uniquement à titre purement informatif et ne sauraient constituer en aucun cas un conseil ou une recommandation de quelque nature que ce soit.</p>
    <p>En conséquence, l'utilisation des informations et contenus disponibles sur tout ou partie du Site ne saurait en aucun cas engager notre responsabilité, à quelque titre que ce soit.</p>
    <p>Dans la limites des dispositions légales impératives applicables, en aucun cas, y compris mais non limité en cas de négligence, Gotta Apps ou ses sociétés affiliées, fournisseurs, clients ou fournisseurs (collectivement, les "entités
      protégées") ne pourront être tenues responsables des dommages indirects, particuliers, accessoires, consécutifs, exemplaires ou punitifs découlant de, ou liés directement ou indirectement à l'utilisation ou l'incapacité d'utiliser les services
      ou le contenu, le matériel et les fonctions qui lui sont associée, votre fourniture d'informations par les services, ou perte d'activité ou la perte de ventes, ou des erreurs, virus ou de bogues contenus dans les services, même si une telle
      entité protégée a été informé de la possibilité de tels dommages. En aucun cas, les entités protégées ne peuvent être tenues responsables pour ou en relation avec tout contenu affiché, transmis, échangé ou reçu par ou pour le compte d'un
      utilisateur ou d'une autre personne ou par l'intermédiaire des services. En aucun cas, la responsabilité globale des entités protégées à votre égard pour tous dommages, pertes et suites d'évènements et d'actions (contractuelle ou délictuelle,
      y compris, mais sans s'y limiter, la négligence ou autre) découlant de ces Conditions ou de votre utilisation des services ne dépassera, au total, la somme la moins élevée de ces sommes : (i) soit le montant que vous avez, le cas échéant, payé
      à Gotta Apps pour votre usage des services au cours des 12 mois précédents ou (ii) soit la somme de 100 (cent) euros.</p>
    <p>Rien dans la présente clause n'est de nature à limiter la responsabilité de Gotta Apps ou des entités protégées en case de dol, faute lourde ou de dommages corporels.</p>
    <p>&nbsp;</p>
    <p><strong>11. Avertissement médical</strong></p>
    <p>Gotta Apps est un fournisseur d'abonnements en ligne et mobiles pour accéder à du contenu relatif au yoga, dans le domaine de la santé et du bien-être. Nous ne sommes pas un dispositif médical, nous ne fournissons pas des conseils
      médicaux. Bien qu'il existe des preuves établis par des tiers que le yoga peut aider dans le processus de prévention et de récupération pour un large éventail de maladies, ainsi que dans la gestion d'un certain nombre de problèmes de
      performances et relationnels, Gotta Apps n'affirme, ni ne garantit que ce sera le cas en cas d'utilisation de nos services. Tous les utilisateurs ayant de graves problèmes médicaux ou psychologiques ou nécessitant un avis médical doivent
      consulter leur médecin.</p>
    <p>Toutes les informations et liens depuis nos services relatifs à la santé, qu'ils soient fournis par Gotta Apps ou par des prestataires extérieurs contractuels, sont fournis uniquement pour votre information et votre commodité.</p>
    <p>Tous les conseils ou autres éléments disponibles dans nos services sont destinés uniquement à des fins d'information générale. Ils ne sont pas destinés à servir de référence et de substitut à un avis médical professionnel basé sur votre
      situation personnelle. Les conseils et les autres éléments du site sont destinés à aider la relation entre vous et vos fournisseurs de soins et de santé mais pas à la remplacer. Nous ne pouvons pas être tenus responsables de toute mesure ou
      action prise en raison de ou après avoir lu ou entendu nos conseils et contenus ou après avoir utilisé nos services. En particulier, dans la mesure où cela est permis par la loi, nous ne donnons aucune garantie quant à l'exactitude,
      l'exhaustivité ou la pertinence (pour quoi que ce soit) d'un avis et d'une information publiée dans le cadre nos services.</p>
    <p>&nbsp;</p>
    <p><strong>12. Données personnelles</strong></p>
    <p>Les données à caractère personnel ainsi recueillies sur le Site sont traitées conformément à la loi sur la protection des personnes à l'égard du traitement des données à caractère personnel. Vous disposez d'un
      droit d'accès et de rectification aux données vous concernant, sous réserve de disposer d'un interêt légitime, et vous pouvez, sous réserve de justifier de raisons prépondérantes et légitimes, vous opposer à leur traitement en vous adressant à
      <a href="mailto:info@gottayoga.app">info@gottayoga.app</a>.</p>
    <p>Gotta Apps se réserve, dans l'hypothèse où le membre se rendrait responsable d'une infraction à la législation en vigueur ou d'une atteinte aux droits des tiers, le droit de fournir, à la demande de toute autorité légitime (juridiction,
      autorité administrative, services de police), tous renseignements permettant ou facilitant l'identification du membre contrevenant.</p>
    <p>Pour plus d'informations, merci de consulter notre "Politique de confidentialité" qui est accessible en ligne et fait partie intégrante des présentes Conditions.</p>
    <p>&nbsp;</p>
    <p><strong>13. Dispositions générales</strong></p>
    <p>13.1. Cession</p>
    <p>Gotta Apps peut transférer ses droits et obligations en vertu de ces Conditions à toute société, entreprise ou personne à tout moment si cela ne modifie pas sensiblement vos droits concernés. Vous ne pouvez pas transférer vos droits ou
      obligations en vertu de ces Conditions à quelqu'un d'autre, sauf accord préalable et écrit de Gotta Apps.</p>
    <p>13.2. Garantie et indemnités</p>
    <p>Vous acceptez de défendre, d'indemniser et de soutenir Gotta Apps et ses administrateurs, dirigeants, membres, investisseurs, employés et agents de toute réclamation, invocation de la responsabilité, coûts et dépenses, y compris
      les honoraires raisonnables d'avocat, qui pourraient découler de quelque façon de votre utilisation des services, de votre publication ou transmission de tout message, contenu, informations, logiciels ou autres communications à travers nos
      services, ou de votre violation de la loi ou de ces termes. Gotta Apps se réserve le droit, à ses propres frais, d'assumer la défense exclusive et le contrôle de toute affaire par ailleurs susceptible de nécessiter une indemnisation par vous,
      et dans ce cas, vous acceptez de coopérer avec la défense de Gotta Apps d'une telle réclamation.</p>
    <p>13.3. Aucune renonciation</p>
    <p>Si nous retardons l'exercice ou ne parvenons pas à exercer ou à appliquer tout droit à notre disposition en vertu de ces Conditions, un tel retard ou l'échec ne constitue pas une renonciation à ce droit ou à d'autres droits en vertu de ces
      termes.</p>
    <p>13.4. Force majeure</p>
    <p>Nous ne serons pas responsables envers vous pour tout manque de performance, indisponibilité ou panne des services, ou pour tout manquement ou retard de notre part pour se conformer à ces conditions, dans le cas où ce manque de performance,
      indisponibilité ou panne est dû à une cause hors de notre contrôle raisonnable.</p>
    <p>13.5. Interprétation</p>
    <p>Dans ces Conditions, à moins que le contexte ne s'y oppose: i) une phrase introduite par les mots "y compris", "comprend", "en particulier", "par exemple"ou toute expression similaire doit être interprétée comme illustration seulement et ne
      doit pas être interprétée comme limiter la généralité de tous les mots qui précèdent; et ii) les références au singulier comprennent le pluriel et le masculin inclut le féminin, et dans chaque cas vice versa.</p>
    <p>13.6. Communications écrites</p>
    <p>Les lois applicables exigent que certaines des informations ou communications que nous vous envoyons se fassent par écrit. Lorsque vous utilisez le Site et ses services, vous acceptez que les communications avec nous soient principalement
      électroniques. Nous vous contacterons par e-mail ou vous fournirons des informations en publiant sur le Site des avis sur les services. Vous acceptez ces moyens de communications électroniques et vous reconnaissez que tous les contrats, avis,
      informations et autres communications que nous vous fournissons électroniquement ont la même valeur que les communications faites par écrit.</p>
    <p>13.7. Preuve /Archivage des preuves</p>
    <p>Les données informatiques générées en raison de l'accès et/ou de l'utilisation du Site (y incluant, notamment, les programmes, données de connexion, fichiers, enregistrements, opérations et autres éléments sur support informatique ou
      électronique) (les "Données") sont susceptibles d'être conservées et archivées, dans la limite prévue par la loi, par nos soins au sein de notre système informatique, dans des conditions de nature à en garantir l'intégrité.</p>
    <p>Les Données font foi entre vous et nous, et seront considérées comme les preuves des communications entre vous et nous.</p>
    <p>Les Données constituent une preuve littérale et ont la même force probante qu'un document qui serait établi, reçu ou conservé par écrit sur support papier.</p>
    <p>Par conséquent, les Données pourront être valablement produites comme moyens de preuve et vous être opposées dans le cadre de toute réclamation ou action en justice avec la même force probante que tout document qui serait établi, reçu ou
      conservé par écrit sur support papier, ce que vous reconnaissez expressément.</p>
    <p>Nous nous engageons à ne pas contester la recevabilité, l'opposabilité ou la force probante des Données en raison de leur nature électronique. Vous vous engagez de même.</p>
    <p>Vous pouvez avoir accès à l'ensemble des Données sur simple demande adressée par courrier simple ou par courrier électronique.</p>
    <p>13.8. Divisibilité</p>
    <p>Si un tribunal ou une autorité compétente décide que l'une des dispositions de ces Conditions n'est pas valide, ou est illégale ou inapplicable dans une certaine mesure, la disposition sera, dans cette mesure seulement, être séparé des autres
      dispositions, qui continueront à être valables dans toute la mesure permise par la loi.</p>
    <p>13.9. Intégralité de l'accord</p>
    <p>Ces Conditions, ainsi que les conditions supplémentaires éventuelles et tout autre document expressément nommé dans ces termes représentent l'intégralité de l'accord entre nous et remplacent toutes les précédentes discussions,
      correspondances, négociations, arrangements précédents, entente ou accord entre nous concernant l' objet de ces termes. Nous reconnaissons qu'aucun de nous se fonde sur, une déclaration ou une garantie (si faite involontairement ou par
      négligence) qui ne figurent pas dans ces termes ou les documents auxquels ils se réfèrent Chacun d'entre nous accepte que notre seul recours à l'égard de ces déclarations et garanties tels qu'énoncés dans cet accord (si fait involontairement
      ou par négligence) sera la rupture de contrat. Rien dans cette section ne limite ou n'exclut la responsabilité en cas de fraude ou de vol.</p>
    <p>13.10. Droit applicable</p>
    <p>Les présentes Conditions sont soumises au droit français.</p>
    <p>Sous réserve des dispositions légales impératives applicables, les tribunaux français seront exclusivement compétents pour connaître de tout litige lié à l'interprétation, l'application et l'exécution des présentes Conditions, ainsi que
      de tout litige lié à l'utilisation du Site.</p>
    <p>Ces Conditions sont effectives à compter de leur dernière mise à jour le 7 novembre 2023.</p>
  </v-container>
</base-section>
</template>

<script>
export default {
  name: 'Conditions',
}
</script>
