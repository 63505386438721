import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
import Routes from './routes/Routes'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import VueNumericInput from 'vue-numeric-input'
import { initLanguage, subscribeURL } from '@/common/language'


import en from './en.json'
import fr from './fr.json'
import de from './de.json'
import it from './it.json'
import fi from './fi.json'

// my style, theme, colours, fonts ...
import './styles/app.scss';

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(VueI18n);
Vue.use(VueNumericInput);

Vue.use(VueCookieBot, {
  cookieBotID: '90041123-2896-4057-8c16-86c3df8608fb'
})

const router = new VueRouter({
  routes : Routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    console.log("from: " + from + ", to:" + to)
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Ready translated locale messages
const messages = {
  en: en,
  fr: fr,
  de: de,
  it: it,
  fi: fi
}

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: (navigator.language || navigator.userLanguage).split('-')[0],
  messages, // set locale messages
})

if (typeof firebase === 'undefined') throw new Error('Firebase SDK not detected.');
firebase.initializeApp({
  apiKey: "AIzaSyD2a7UyrZ9QolpfMjV5dcUzDmOv7oKi0SU",
  databaseURL: "https://gotta-joga.firebaseio.com",
  storageBucket: "gotta-joga.appspot.com",
  authDomain: "gotta-joga.firebaseapp.com",
  messagingSenderId: "2872738006",
  projectId: "gotta-joga",
  appId: "1:2872738006:web:3f152ab008dca1fec28977"
})

try {
  const app = firebase.app();
  let features = ['auth', 'database', 'messaging', 'storage'].filter(feature => typeof app[feature] === 'function');
  console.log(`Firebase SDK loaded with ${features.join(', ')}`);
  // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
} catch (e) {
  console.log('Error loading the Firebase SDK. Check the console.');
  console.error(e);
}

// Check before each page load whether the page requires authentication/
// if it does check whether the user is signed into the web app or
// redirect to the sign-in page to enable them to sign-in
router.beforeEach(async (to, from, next) => {
  await initLanguage(to.params.language)

  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    next({ path: '/login', query: { redirect: to.path } })
  } else if (requiresAuth && currentUser) {
    const requiresSubscription = to.matched.some(record => record.meta.requiresSubscription)
    if (requiresSubscription) {
      // Workaround for free classes
      if (to.path.startsWith("/course")) {
        const uid = Number(to.params.id)
        if (uid >= 137 && uid <= 148) {
          next()
          return
        }
      }

      if (currentUser.uid === "xEiBzzoIpAMXJTm45fzCMqR8oXn2") {
        next()
      } else {
        const unlockAllRef = firebase.database().ref('/users/' + currentUser.uid + '/unlockAll');
        const unlockAll = await unlockAllRef.once('value').then((snapshot) => {
          return snapshot.val()
        })
        if (unlockAll === true) {
          next()
        } else {
          next(subscribeURL())
        }
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

firebase.auth().onAuthStateChanged(function(user) {
  if (user !== null) {
    console.log("Auth changed");
  }
  new Vue({
    vuetify,
    render: h => h(App),
    i18n,
    router
  }).$mount('#app')
});
