export default {
    name: 'Home',
    components: {
        SectionHero: () => import('@/components/sections/home/Hero'),
        SectionFeatures: () => import('@/components/sections/home/Features'),
        SectionHerosmall: () => import('@/components/sections/home/HeroSmall'),
        SectionColImgTxt: () => import('@/components/sections/home/ColImgTxt'),
        SectionTeachers: () => import('@/components/sections/home/Teachers'),
        SectionRating: () => import('@/components/sections/home/Rating'),
        // SectionNewsletter: () => import('@/components/sections/home/Newsletter'),
        BaseScrollButton: () => import('@/components/base/ScrollButton'),
        BaseContact: () => import('@/components/base/Contact'),
        BaseSocialMedia: () => import('@/components/base/SocialMedia'),
        BaseErrorMsg: () => import('@/components/base/ErrorMsg'),
  },
  data() {
    const getStartedLink = '/programs'
    return {
      getStartedLink: getStartedLink,
      hero: [
        {
          title: this.$i18n.t("app_name"),
          subtitle: this.$i18n.t("yoga_moment"),
          text: this.$i18n.t("home_page_cta_text"),
          btnTxt: this.$i18n.t("home_page_cta"),
          btnLink: getStartedLink,
          image: require("@/assets/img/meditation.jpeg")
        }
      ],
      herosmall: [
        {
          title: this.$i18n.t("mission_title"),
          text: this.$i18n.t("mission_text"),
          btnTxt: this.$i18n.t("mission_cta"),
          btnLink: getStartedLink,
          image: require("@/assets/img/herosmall.jpg")
        }
      ],
      colimgtxt: [
        {
          title: this.$i18n.t("feature2"),
          text: this.$i18n.t("many_classes_text"),
          btnTxt: this.$i18n.t("mission_cta"),
          btnLink: getStartedLink,
          image: require("@/assets/img/devices.png")
        }
      ],
      // newsletter: [
      //   {
      //     title: this.$i18n.t("newsletter_title"),
      //     subtitle: this.$i18n.t("newsletter_subtitle"),
      //     btnTxt: this.$i18n.t("newsletter_cta"),
      //     btnLink: '#',
      //     image: require("@/assets/img/meditation.jpeg")
      //   }
      // ],
      errorMessage: {
        title: 'Erreur',
        text: 'Lorem ipsum dolor sit amet consecutor...',
        btns: [
          { txt: 'D\'accord', link: '#check' },
          { txt: 'Pas d\'accord', link: '#disagree' },
        ]
      }
    };
  }
}
